import globalConfig from "@/config/config.js"
import { $$language } from "@/i18n/i18n.js"
import { getType, getDeviceId, getUrlAllParams } from "@/utils/tools.js"
// import sa from "sa-sdk-javascript"
import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js"
import { isIos } from "@/utils/cookie"

const headerConfig = {
  "X-App-Name": globalConfig.appname,
}

let AuthData = {
  auth_secret: "8I7RZPf0SOIoCKIT9e8WrteSbFDBFzXI",
  auth_key: "mH8HP1kMFBhT8c3gFgFWrJolHsuAY4nX",
  user_id: 1990,
}

const getAuthorization = () => {
  const { oauth_key, auth_secret, timestamp } = AuthData
  return `oauth_token=${oauth_key},oauth_signature=${auth_secret},oauth_timestamp=${timestamp}`
}
const getUserAgent = async () => {
  //  return 'en/1.0.1/h5//web/635e3f65711348759f54180b91220156/unknown//3x';
  const osVersion = isIos() ? "ios" : "android"
  const deviceId = await firebaseUtils.getAnonymousID()
  const netType = "unknown"
  const deviceType = ""
  const resolution = "3x"
  const plantform = globalConfig.platform
  // $$language()
  return ["cn", globalConfig.version, plantform, osVersion, "web", deviceId, netType, deviceType, resolution].join("/")
}
export const paramFormat = (data = {}) => {
  const result = {}
  const time = new Date().getTime()
  /* 0 app内部进入 1 外部浏览进入 */
  Object.assign(result, {
    time,
    ...data,
  })
  return result
}

export const updateHeaderConfig = (authData) => {
  console.log("updateHeaderConfig", authData)
  AuthData = authData
}

export const getPublicHeader = async () => {
  const gaInfo = document.cookie?.match(/_ga=(.+?);/)?.[1] ?? ""
  console.log('gaInfo',gaInfo)
  const gaUserId = gaInfo?.split(".")?.slice(-2)?.join(".") ?? ""
  return {
    ...headerConfig,
    // 'Authorization': getAuthorization(),
    "X-User-Agent": await getUserAgent(),
    "X-Auth-Timestamp": Math.floor(new Date().getTime()),
    "X-Source": getLatestUtmSourceFromCookie() ?? "",
    "X-Firebase-Id": gaUserId ?? "",
  }
}

// 方法：从 cookie 中获取 sensorsdata2015jssdkcross 并解析出 $latest_utm_source
export const getLatestUtmSourceFromCookie = () => {
  // 获取所有 cookie
  const cookies = document.cookie

  // 查找名为 "sensorsdata2015jssdkcross" 的 cookie
  const match = cookies.match(/sensorsdata2015jssdkcross=([^;]+)/)

  if (match) {
    try {
      // 解析该 cookie 的值（JSON 格式）
      const sensorsData = JSON.parse(decodeURIComponent(match[1]))

      // 从 props 中获取 $latest_utm_source
      const latestUtmSource = JSON.stringify(sensorsData.props ?? "") ?? ""
      // 返回 $latest_utm_source 的值
      return objectToBase64(latestUtmSource) ?? ""
    } catch (error) {
      console.error("Failed to parse sensorsdata2015jssdkcross cookie:", error)
      return null
    }
  } else {
    console.warn("No sensorsdata2015jssdkcross cookie found")
    return null
  }
}

export const objectToBase64 = (obj) => {
  const jsonString = JSON.stringify(obj)
  const encoder = new TextEncoder()
  const utf8Bytes = encoder.encode(jsonString)
  const base64String = btoa(String.fromCharCode(...utf8Bytes))
  return base64String
}

export const getUseSource = () => {
  const adSourceStorage = window.sessionStorage.getItem("ad_source") ?? ""
  if (adSourceStorage) {
    const { source: storedSource, bd_vid: storedBdVid } = JSON.parse(adSourceStorage)
    return storedSource === "baidu" ? `baidu_bd_vid=${storedBdVid}` : storedSource
  }

  return null // 或者返回一个默认值
}

// 统一返回格式
// res = {
//   code: '',
//   data: {},
//   "msg": "xxxxx"
// };

export const interfaceSpecification = (data) => {
  // 接口规范
  if (getType(data) === "Object") {
    return data
  } else {
    return data
    console.error("非标准接口数据格式", data)
  }
}
