import { defineStore } from "pinia";
import feedListApi from "@/api/api/feedListApi.js";
import useGlobalPlayDataStore from "@/store/globalPlayDataStore.js"
import { getRecommendSonglist, getHomeNewSongs, getRecommendGenreSongList, getSongGenreList, getSongsByGenre } from "@/api/api/apiDiscover.js"
import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js";

const useNewSongsData = defineStore({
    id: "newSongsStoreData",
    state: () => {
        return {
            scrollHeight: 0,
            // trending songs 数据
            trendingSongList: [],
            // 曲风ABC列表数据
            genreSong: [],
            // 曲风合集列表数据
            genreCollection: [],
            // new songs数据
            newSongsRnderData: {
                list: [],
                refreshing: false,
                loadingMore: false,
                isLoading: false,
                loadError: false,
                finished: false,
            },
            newSongsQueryLastId: 0,
            genreSongsRenderData: {
                list: [],
                refreshing: false,
                loadingMore: false,
                isLoading: false,
                loadError: false,
                finished: false,
            },
            genreSongsQueryLastId: 0,
            genreSongsTotal: 0,
            genreDetailScrollHeight: 0,
            trendindActiveIndex: 0
        };
    },
    actions: {
        // 获取trending songs
        getTrendingSongs() {
            getRecommendSonglist({}).then(res => {
                if (res.code == 200) {
                    const { data } = res;
                    this.trendingSongList = data.feeds
                }
            })
        },

        // 曲风ABC列表
        getGenreSongList() {
            getRecommendGenreSongList({}).then(res => {
                if (res.code == 200) {
                    const { data } = res;
                    this.genreSong = data.genre_recommend_songs
                }
            })
        },

        // 曲风合集
        getTotalGenreList() {
            getSongGenreList({
                priority: 2
            }).then(res => {
                if (res.code == 200) {    
                    const { data } = res;
                    this.genreCollection = data?.feeds
                }
            })
        },

        // 上拉加载
        doLoadMore(done) {
            this.newSongsRnderData.isLoading = true;
            getHomeNewSongs({
                page_size: 20,
                last_id: this.newSongsQueryLastId
            })
                .then((res) => {
                    if (res.code == 200) {
                        const globalPlayDataStore = useGlobalPlayDataStore();
                        this.newSongsRnderData.loadError = false;
                        const { data } = res;
                        if (!data.feeds) {
                            done(this.newSongsRnderData, true);
                        } else {
                            this.newSongsRnderData.list = [...this.newSongsRnderData.list, ...data.feeds];
                            globalPlayDataStore.addPlayDatas(this.formatData(this.newSongsRnderData.list))
                            this.newSongsQueryLastId = data?.last_id ? data?.last_id : 0;
                            done(this.newSongsRnderData, false);
                        }
                    } else {
                        this.newSongsRnderData.loadError = true;
                    }
                })
                .finally(() => { });
        },

        // 重新加载
        doRetry() {
            this.newSongsRnderData.isLoading = true;
            this.newSongsQueryLastId = 0
            getHomeNewSongs({
                page_size: 20,
                last_id: this.newSongsQueryLastId
            }).then((res) => {
                if (res.code == "200") {
                    this.newSongsRnderData.isLoading = false;
                    this.newSongsRnderData.loadError = false;
                    const { data } = res;
                    if (!data.feeds) {
                        this.newSongsRnderData.finished = true;
                        this.newSongsRnderData.list = []
                    } else {
                        this.newSongsRnderData.list = data.feeds;
                        this.newSongsQueryLastId = data?.last_id ? data?.last_id : 0;
                        this.newSongsRnderData.finished = false;
                    }

                } else {
                    this.newSongsRnderData.isLoading = false;
                    this.newSongsRnderData.loadError = true;
                }
            }).finally(() => {
            });
        },

        // 重新加载曲风下的歌曲
        doGenreRetry(query) {
            this.genreSongsRenderData.isLoading = true;
            this.genreSongsQueryLastId = 0
            getSongsByGenre({
                ...query,
                size: 20,
                last_id: this.genreSongsQueryLastId,
                total: this.genreSongsTotal
            }).then((res) => {
                if (res.code == "200") {
                    this.genreSongsRenderData.isLoading = false;
                    this.genreSongsRenderData.loadError = false;
                    const { data } = res;
                    if (!data.feeds) {
                        this.genreSongsRenderData.finished = true;
                        this.genreSongsRenderData.list = []
                    } else {
                        this.genreSongsRenderData.list = data.feeds;
                        this.genreSongsQueryLastId = data?.last_id || 0;
                        this.genreSongsRenderData.finished = false;
                        this.genreSongsTotal = data?.total || 0
                    }

                } else {
                    this.genreSongsRenderData.isLoading = false;
                    this.genreSongsRenderData.loadError = true;
                }
            }).finally(() => {
            });
        },

        doGenreRefresh(done, query) {
            this.genreSongsRenderData.isLoading = true;
            this.genreSongsQueryLastId = 0
            getSongsByGenre({
                ...query,
                size: 20,
                last_id: this.genreSongsQueryLastId,
                total: this.genreSongsTotal
            }).then((res) => {
                if (res.code == 200) {
                    const { data } = res;
                    if (!data.feeds) {
                        done(this.genreSongsRenderData, true);
                    } else {
                        this.genreSongsRenderData.list = data.feeds;
                        this.genreSongsQueryLastId = data?.last_id ? data?.last_id : 0;
                        done(this.genreSongsRenderData, false);
                        this.genreSongsTotal = data?.total || 0
                    }
                } else {
                    this.genreSongsRenderData.loadError = true;
                }
            });
        },

        doGenreLoadMore(done, query) {
            this.genreSongsRenderData.isLoading = true;
            getSongsByGenre({
                ...query,
                size: 20,
                last_id: this.genreSongsQueryLastId,
                total: this.genreSongsTotal
            })
                .then((res) => {
                    if (res.code == 200) {
                        const globalPlayDataStore = useGlobalPlayDataStore();
                        this.genreSongsRenderData.loadError = false;
                        const { data } = res;
                        if (!data.feeds) {
                            done(this.genreSongsRenderData, true);
                        } else {
                            this.genreSongsRenderData.list = [...this.genreSongsRenderData.list, ...data.feeds];
                            globalPlayDataStore.addPlayDatas(this.formatData(this.genreSongsRenderData.list))
                            this.genreSongsQueryLastId = data?.last_id ? data?.last_id : 0;
                            this.genreSongsTotal = data?.total || 0
                            done(this.genreSongsRenderData, false);
                        }
                    } else {
                        this.genreSongsRenderData.loadError = true;
                    }
                })
                .finally(() => { });
        },

        // 更新曲风列表喜欢状态
        updateGrnreLike(data, favorite_state) {
            this.genreSong = this.genreSong.map(item => (
                {
                    ...item,
                    feeds: item.feeds.map(feed =>
                        feed.song.song_id === data.song.song_id
                            ? { ...feed, is_liked: !favorite_state }
                            : feed
                    )
                }
            ));
        },

        // 更新推荐列表喜欢状态
        updateTrendingCardLike(data, favorite_state) {
            this.trendingSongList = this.trendingSongList.map(item => {
                return {
                    ...item,
                    is_liked: item.song.song_id == data.song.song_id ? !favorite_state : item.is_liked
                }
            })
        },

        // 更新new songs列表喜欢状态
        updateNewSongCardLike(data, favorite_state) {
            let index = this.newSongsRnderData.list.findIndex(
                (itemF) => itemF.song.song_id === data.song.song_id
            );
            if (index > -1) {
                const item = this.newSongsRnderData.list[index]
                Object.assign(this.newSongsRnderData.list[index], {
                    ...item,
                    is_liked: !favorite_state

                });
                this.newSongsRnderData.list = [].concat(this.newSongsRnderData.list);
            }
        },

        // 更新曲风全部列表喜欢状态
        updateGenreSongsLike(data, favorite_state) {
            let index = this.genreSongsRenderData.list.findIndex(
                (itemF) => itemF.song.song_id === data.song.song_id
            );
            if (index > -1) {
                this.genreSongsRenderData.list[index] = {
                    ...this.genreSongsRenderData.list[index],
                    is_liked: !favorite_state
                };
            }
        },

        updateDiscoverScrollTop(height) {
            this.scrollHeight = height
        },

        updateGenreScrollTop(height) {
            this.genreDetailScrollHeight = height
        },

        formatData(list) {
            const array = list.map(item => {
                return {
                    ...item.song,
                    favorite_state: item.is_liked ? 1 : 2,
                    uid: item.user.user_id,
                    user: item.user
                }
            })
            return array
        },

        formatGenreData() {
            const list = []
            this.genreSong.forEach(item => {
                item.feeds.forEach(citem => {
                    list.push({
                        ...citem.song,
                        favorite_state: citem.is_liked ? 1 : 2,
                        uid: citem.user.user_id,
                        user: citem.user
                    })
                })
            })
            return list
        },



        addTrendingPlayList() {
            const globalPlayDataStore = useGlobalPlayDataStore();
            globalPlayDataStore.setCurPlayGroup('trendingList');
            globalPlayDataStore.playlist = [];
            globalPlayDataStore.addPlayDatas(this.formatData(this.trendingSongList));
        },

        toggleTrendingPlay(item) {
            const globalPlayDataStore = useGlobalPlayDataStore();
            globalPlayDataStore.setCurPlayGroup('trendingList')
            globalPlayDataStore.setPlayDatas(this.formatData(this.trendingSongList), {
                ...item.song,
                favorite_state: item.is_liked ? 1 : 2,
                uid: item.user.user_id,
                user: item.user,
            })
        },

        addGenrePlayList() {
            const globalPlayDataStore = useGlobalPlayDataStore();
            globalPlayDataStore.setCurPlayGroup('genreList');
            globalPlayDataStore.playlist = [];
            globalPlayDataStore.addPlayDatas(this.formatGenreData());
        },

        toggleGenrePlay(item) {
            const globalPlayDataStore = useGlobalPlayDataStore();
            globalPlayDataStore.setCurPlayGroup('genreList')
            globalPlayDataStore.setPlayDatas(this.formatGenreData(), {
                ...item.song,
                favorite_state: item.is_liked ? 1 : 2,
                uid: item.user.user_id,
                user: item.user,
            })
        },

        addNewSongsPlayList() {
            const globalPlayDataStore = useGlobalPlayDataStore();
            globalPlayDataStore.setCurPlayGroup('newSongList');
            globalPlayDataStore.playlist = [];
            globalPlayDataStore.addPlayDatas(this.formatData(this.newSongsRnderData.list));
        },

        toggleNewSongsPlay(item) {
            const globalPlayDataStore = useGlobalPlayDataStore();
            globalPlayDataStore.setCurPlayGroup('newSongList')
            globalPlayDataStore.setPlayDatas(this.formatData(this.newSongsRnderData.list), {
                ...item.song,
                favorite_state: item.is_liked ? 1 : 2,
                uid: item.user.user_id,
                user: item.user,
            })
        },

        toggleGenreDetailPlay(item) {
            const globalPlayDataStore = useGlobalPlayDataStore();
            globalPlayDataStore.setCurPlayGroup('genreTotalList')
            globalPlayDataStore.setPlayDatas(this.formatData(this.genreSongsRenderData.list), {
                ...item.song,
                favorite_state: item.is_liked ? 1 : 2,
                uid: item.user.user_id,
                user: item.user,
            })
        },

        updateGlobalPlayFavorite(song_id, favorite_state) {
            // this.trendingSongList = this.trendingSongList.map(item => {
            //     return {
            //         ...item,
            //         is_liked: item.song.song_id == song_id ? favorite_state == 1 ? true : false : item.is_liked
            //     }
            // })
            let trendingIndex = this.trendingSongList.findIndex(
                (item) => item.song.song_id === song_id
            );
            let newItem = {
                ...this.trendingSongList[trendingIndex],
                is_liked: favorite_state == 1 ? true : false
            }
            this.trendingSongList[trendingIndex] = newItem
            // this.trendingSongList.splice(trendingIndex, 1, newItem);
            this.genreSong = this.genreSong.map(item => (
                {
                    ...item,
                    feeds: item.feeds.map(feed =>
                        feed.song.song_id === song_id
                            ? { ...feed, is_liked: favorite_state == 1 ? true : false }
                            : feed
                    )
                }
            ));
            let index = this.newSongsRnderData.list.findIndex(
                (itemF) => itemF.song.song_id === song_id
            );
            if (index > -1) {
                const item = this.newSongsRnderData.list[index]
                Object.assign(this.newSongsRnderData.list[index], {
                    ...item,
                    is_liked: favorite_state == 1 ? true : false

                });
                this.newSongsRnderData.list = [].concat(this.newSongsRnderData.list);
            }

            let totlaIndex = this.genreSongsRenderData.list.findIndex(
                (itemF) => itemF.song.song_id === song_id
            );
            if (totlaIndex > -1) {
                const item = this.genreSongsRenderData.list[totlaIndex]
                Object.assign(this.genreSongsRenderData.list[totlaIndex], {
                    ...item,
                    is_liked: favorite_state == 1 ? true : false

                });
                this.genreSongsRenderData.list = [].concat(this.genreSongsRenderData.list);
            }
        },

        doPlayStateReport(item, play_type) {
            return new Promise((resolve, reject) => {
                feedListApi
                    .doPlayStateReport({
                        song_id: item.song.song_id,
                        play_type: play_type,
                    })
                    .then((res) => {
                        if (res.code == 200) {
                            resolve();
                        } else {
                            reject(res?.msg ?? "save failed");
                        }
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },

        clearGenreListTotal() {
            this.genreSongsTotal = []
            this.genreSongsRenderData = {
                list: [],
                refreshing: false,
                loadingMore: false,
                isLoading: false,
                loadError: false,
                finished: false,
            }
        },

        updateTrendingActiveIndex(index) {
            this.trendindActiveIndex = index
        },

        // 初始化已上报列表及上报列表
        initPreviewList() {
            sessionStorage.setItem('discover_reported_list', JSON.stringify([]))
            sessionStorage.setItem('discover_show_list', JSON.stringify([]))
        },

        // 维护上报列表数据(需要去重)
        addShowItem(song_id) {
            const discover_show_list = JSON.parse(
                sessionStorage.getItem("discover_show_list")
            );
            if (!discover_show_list.includes(song_id)) {
                discover_show_list.push(song_id);
                sessionStorage.setItem(
                    "discover_show_list",
                    JSON.stringify(discover_show_list)
                );
                console.log(discover_show_list);
            }
        },

        // 上报逻辑
        reportDiscoverList() {
          let discover_reported_list = JSON.parse(sessionStorage.getItem('discover_reported_list')) || []
          let discover_show_list = JSON.parse(sessionStorage.getItem('discover_show_list')) || []
          if (!discover_reported_list.length) {
            if (discover_show_list.length) {
                discover_reported_list = [...discover_reported_list, discover_show_list]
                return discover_show_list
            }
          } else {
            const result = discover_show_list.filter(item => !discover_reported_list.includes(item));
            discover_reported_list = [...discover_reported_list, result]
            return result
          }
        },

        // 清空显示list
        clearShowList() {
            sessionStorage.setItem('discover_show_list', JSON.stringify([]))
        }
    }
})

export default useNewSongsData;