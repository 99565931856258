<template>
  <div class="c-modal" v-if="pageVisible">
    <div class="mask">
      <Rights v-if="rightType" :rightType="rightType" :create_type="props.create_type" :source="props.source" />
      <div class="content">
        <div class="mobile-title">
          <div class="left">登录</div>
          <img @click="closePage" src="@/assets/img/mobile/icon-close.png" alt="" />
        </div>
        <div class="close-btn" @click="closePage">
          <img src="@/assets/img/ic_close.png" alt="" />
        </div>
        <div class="flex-center bg-img">
          <div class="login-module-main login-in-main">
            <div class="login-img-box">登录</div>
            <div class="login-in-main-content">
              <LoginCn @submit="submit" />
            </div>
            <div class="terms">
              <div class="checkbox" :class="{ active: termCheckbox }" @click="changeRadioValue"></div>
              <div class="text-box">
                请阅读并同意<a href="/terms" class="a-link btn pointer">《音疯用户协议》</a>
                <a href="/privacy" class="a-link btn pointer">《音疯隐私政策》</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, onMounted, readonly, reactive, watch, defineExpose, triggerRef, getCurrentInstance } from "vue"
import message from "@/components/functionCallComponent/message/message.js"
import ShowActionDialog from "@/components/functionCallComponent/action/action.js"
import apiUser from "@/api/api/apiUser.js"
import { useRouter } from "vue-router"
import useUserDataStore from "@/store/userDataStore"
import { funComponentList } from "@/components/functionComp/index"
import useShopCartStore from "@/store/shopCartStore.js"
import Btn from "@/components/basic/button/Button.vue"
import { getParameterByName } from "@/utils/tools.js"
import LoginCn from "@/components/login/LoginFormCn.vue"
import Rights from "./rights.vue"
import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js"
import { getUseSource } from "@/api/net/config.js"
//自定义函数组件无法使用全局组件，需要单独引入
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  okText: {
    type: String,
    default: "确定",
  },
  handleOk: {
    type: Function, //成功回调
    default: null,
  },
  remove: {
    type: Function, //传入移除节点方法,这里是createApp中的方法
    default: null,
  },
  content: {
    type: String,
    default: "自定义全局函数组件......",
  },
  rightType: {
    type: String, // 'generate' | 'login' | undefined, // 显示权益的类型
    default: "login",
  },
  create_type: {
    type: String,
    default: "2",
  },
  source: {
    type: String,
    default: "other",
  },
})

const pageVisible = ref(false)
pageVisible.value = props.visible
// 监听显示的消失，需要移除dom
watch(
  () => pageVisible.value,
  (val) => {
    !val && props.remove()
  }
)

const termCheckbox = ref(false)

const userDataStore = useUserDataStore()
const shopCartStore = useShopCartStore()

const closePage = () => {
  pageVisible.value = false
  firebaseUtils.logClickEvent(props.rightType === "generate" ? "close_new_user_login" : "close_login", {
    create_type: props.create_type,
    page_route: window.location.pathname,
    source: props.source,
  })
}

const changeRadioValue = () => {
  termCheckbox.value = !termCheckbox.value
}

const submit = async ({ email, password }) => {
  firebaseUtils.logClickEvent(props.rightType === "generate" ? "newuser_login" : "login", {
    create_type: props.create_type,
    loginIsAgree: termCheckbox.value ? "1" : "0",
    source: props.source,
    page_route: window.location.pathname,
  })
  if (!termCheckbox.value) {
    message.error({
      maskClosable: true,
      position: "top",
      content: "请阅读并同意用户协议与隐私政策",
    })
    return
  }
  console.log("submit", email)
  const { data, code, msg } = await apiUser.userLogin({
    token: email,
    mode: "otp_message",
    extra_token: password,
  })
  console.log("submit==>", data, code, msg)
  if (code == 200) {
    console.log("邮箱登录成功")
    updateAuthData(data)
    shopCartStore.getCartTotalCount()
    typeof props.handleOk === "function" && props.handleOk(true)
    pageVisible.value = false
  } else if (code == 3002 || code == 3003) {
    message.error({
      maskClosable: true,
      position: "top",
      content: "发送过于频繁，请稍后再试",
    })
  } else if (code == 3004) {
    message.error({
      maskClosable: true,
      position: "top",
      content: "输入的验证码有误，请重试",
    })
  } else if (code == 3005) {
    message.error({
      maskClosable: true,
      position: "top",
      content: "手机号格式不正确",
    })
  } else if (code == 3006) {
    message.error({
      maskClosable: true,
      position: "top",
      content: "手机号格式不正确",
    })
  } else if (code == 2110) {
    message.error({
      maskClosable: true,
      position: "top",
      content: "您的账号已注销",
    })
  } else {
    console.log("code", code)
    message.error({
      maskClosable: true,
      position: "top",
      content: msg,
    })
  }
}

const updateAuthData = (_data) => {
  userDataStore.upDataAuthData(_data.auth)
  userDataStore.updateLoginStatus(true)
}
</script>

<style lang="scss" scoped>
.c-modal {
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.5);
  .mask {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
  }
  .content {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    background-image: url("@/assets/img/dialog-bg-1.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    border-radius: 20px;
    // width: 300px !important;
    .login-module-main {
      width: 100%;
    }
    .terms {
      padding-top: 23px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      a {
        text-decoration: none;
        color: #94adff;
      }
      .checkbox {
        width: 20px;
        height: 20px;
        background-image: url("@/assets/img/login/radio-empty.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 8px;
        &.active {
          background-image: url("@/assets/img/login/radio-full.png");
        }
      }
      .text-box {
        flex: 1;
        text-align: left;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .mask {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      gap: 24px;
      .close-btn {
        display: none;
      }
    }
    .content {
      box-sizing: content-box;
      margin: 0;
      padding: 28px;
      width: 300px;
    }
  }
}
</style>
