import axiosIS from '@/api/net/axios.js';
import { baseUrl } from '@/config/apiConfig.js';

// 查询推荐区列表
export function getRecommendSonglist(data = {}) {
    return axiosIS(
        `${baseUrl}/api/pgc/song/recommend_list`,
        data,
        { method: 'get' }
    );
}

// 曲风分类，曲风A、B、C 和曲风列表
export function getRecommendGenreSongList(data = {}) {
    return axiosIS(
        `${baseUrl}/api/pgc/song/genre_recommend_songs`,
        data,
        { method: 'get' }
    );
}

// 曲风歌曲分页查询
export function getSongsByGenre(data = {}) {
    return axiosIS(
        `${baseUrl}/api/pgc/song/songs_by_genre`,
        data,
        { method: 'get' }
    );
}

// 曲风列表
export function getSongGenreList(data = {}) {
    return axiosIS(
        `${baseUrl}/api/pgc/song/genre_list`,
        data,
        { method: 'get' }
    );
}

// 广场new songs列表
export function getHomeNewSongs(data = {}) {
    return axiosIS(
        `${baseUrl}/api/pgc/home/songs/new`,
        data,
        { method: 'get' }
    );
}