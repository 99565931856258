<template>
  <div
    class="audio-waveform"
    ref="refAudioWaveform"
    :style="{
      ...renderStyle,
      '--wavebarItemWidth': `${renderData.config.wavebarWidth}px`,
      '--wavebarItemSpacing': `${renderData.config.spacingSize}px`,
      '--progressTransLateX': `${renderData.transLeft}px`,
    }"
    @click="handleSelectBoxClick"
  >
    <div class="audio-waveform-loading" v-if="renderData.loading">
      <template v-for="(item, index) in 8" :key="index">
        <div class="audio-waveform-loading-wave-bar block fn-inline"></div>
      </template>
    </div>
    <div class="audio-waveform-container" v-if="!renderData.loading">
      <AudioWaveRender
        :renderBoxSize="renderData.renderBoxSize"
        :config="renderData.config"
        :waveBarData="renderData.renderWavebarData"
        :activeIndex="renderData.activeIndex"
      ></AudioWaveRender>
      <!-- <template
        v-for="(item, index) in renderData.renderWavebarData"
        :key="index"
      >
        <div
          class="audio-waveform-item"
          :class="{
            'audio-waveform-item-active': renderData.activeIndex > index,
          }"
          :style="{
            color:
              renderData.activeIndex > index
                ? stateData.waveColors[index]
                : renderData.config.defaultColor,
            height: item + 'px',
          }"
        ></div>
      </template> -->
    </div>
    <div
      class="audio-waveform-op"
      v-if="
        !props.hideChangeProgress && !renderData.loading && renderData.isCurPlay
      "
    >
      <div class="audio-waveform-op-progress" ref=""></div>
      <div class="audio-waveform-op-progress-hover" ref="refProgress"></div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  readonly,
  reactive,
  watch,
  defineExpose,
  onMounted,
  onUpdated,
  onUnmounted,
  onBeforeUnmount,
} from "vue";
import AudioWaveRender from "./AudioWaveRender.vue";
import tools from "@/utils/tools.js";
import clipUtil from "@/components/media/utils/clipUtil.js";
import colorUtils from "@/utils/colorUtil.js";
import GlobalAudioPlayer, {
  defaultPersonalizedConfig,
} from "./GlobalAudioPlayer.js";

const defaultConfig = {
  spacingSize: 2,
  wavebarWidth: 1,
  progressTransLateX: 0,
  startColor: "#D282FF",
  endColor: "#D282FF",
  defaultColor: "#D282FF",
  progressColor: "rgba(255, 255, 255, 1)",
};
const props = defineProps({
  renderStyle: {
    type: Object,

    default: () => {
      return {
        "--progressColor": "rgba(255, 255, 255, 1)",
        "--progressHoverColor": "rgba(255, 255, 255, 0.5)",
        "--progressTrasform": "scale(1, 1)",
      };
    },
  },
  config: {
    type: Object,
    default: () => {
      return {
        spacingSize: 3,
        wavebarWidth: 1,
        startColor: "#D282FF",
        endColor: "#D282FF",
        defaultColor: "rgba(255, 255, 255, 1)",
        progressColor: "#D282FF",
      };
    },
  },
  url: {
    type: String,
    default: "",
  },
  waveformData: {
    type: Array,
    default: () => [],
  },
  progress: {
    type: Number,
    default: 0,
  },
  isPlaying: {
    type: Boolean,
    default: false,
  },
  hideChangeProgress: {
    type: Boolean,
    default: false,
  },
  disableChangeProgress: {
    type: Boolean,

    default: false,
  },
});
const emits = defineEmits(["changeProgress"]);

const refAudioWaveform = ref(null);
const refProgress = ref(null);

const stateData = {
  waveColors: [],
};
const defaultRenderStyle = {
  "--progressColor": "#D282FF",
  "--progressHoverColor": "rgba(120, 240, 202, 0.5)",
  "--progressTrasform": "scale(1, 1)",
};
const renderStyle = Object.assign({}, defaultRenderStyle, props.renderStyle);
const renderData = reactive({
  renderWavebarData: [],
  width: 0,
  height: 0,
  renderBoxSize: {
    width: 0,
    height: 0,
  },
  config: {
    spacingSize: 3,
    wavebarWidth: 2,
  },
  transLeft: 0,
  loading: true,
  progress: 0,
  isCurPlay: false,
});

const initConfig = () => {
  renderData.config = Object.assign({}, defaultConfig, props.config);
};
const init = () => {
  return new Promise((resolve, reject) => {
    try {
      renderData.loading = true;
      nextTick(async () => {
        renderData.waveBoxInfo = refAudioWaveform.value.getBoundingClientRect();
        if (!renderData.waveBoxInfo?.width) {
          reject("width is zero");
          return;
        }
        const { width, height } = renderData.waveBoxInfo;
        renderData.width = width;
        renderData.height = height;

        const { spacingSize, wavebarWidth } = renderData.config;
        const waveBarCount = Math.floor(
          (width + spacingSize) / (spacingSize + wavebarWidth)
        );
        renderData.renderBoxSize = {
          width,
          height,
        };
        stateData.waveColors = colorUtils.getGradientColors(
          renderData.config.startColor,
          renderData.config.endColor,
          stateData.waveBarCount
        );
        let wavebarData = [];
        if (props.waveformData?.length) {
          wavebarData = clipUtil.getWaveDataByWaveFormData(
            props.waveformData,
            waveBarCount
          );
        } else {
          if (props.url) {
            try {
              wavebarData = await clipUtil.getWaveDataByUrl(
                props.url,
                waveBarCount
              );
            } catch (e) {
              wavebarData = Array.from({ length: waveBarCount }, () =>
                Math.random()
              );
            }
          } else {
            wavebarData = Array.from({ length: waveBarCount }, () =>
              Math.random()
            );
          }
        }

        renderData.renderWavebarData = clipUtil.fixedPaintHarfHeightWaveBarData(
          wavebarData,
          height
        );

        renderData.loading = false;
        setProgress(props.progress);
        resolve();
      });
    } catch (e) {
      reject(e);
    }
  });
};

const setProgress = (progress) => {
  renderData.activeIndex = Math.ceil(
    renderData.renderWavebarData.length * progress
  );
  if (!stateData.touchSwipeState) {
    renderData.transLeft = renderData.width * progress;
  }
};
const doChangeProgress = () => {
  const width = renderData.width;
  const progress = renderData.transLeft / width;
  // renderData.progress = progress;
  emits("changeProgress", progress);
};
const initEvent = () => {
  stateData.touchSwipeState = false;
  if (props.disableChangeProgress || !props.hideChangeProgress) {
    return;
  }
  let transLeft = 0;

  stateData.registTouchWaveBarSwipeCall = tools.registTouchSwipe(
    refProgress.value,
    {
      direction: "horizontal",
      startCb: (e, actionData) => {
        if (!stateData.touchSwipeState) {
          stateData.touchSwipeState = true;
          transLeft = renderData.transLeft;
        }
      },
      moveCb: (e, actionData) => {
        const { startX, endX } = actionData;
        const dx = Math.floor(endX - startX);
        const width = renderData.width;
        let newTransLeft = transLeft + dx;
        newTransLeft = Math.max(0, Math.min(newTransLeft, width));
        renderData.transLeft = newTransLeft;
      },
      endCb: (e, actionData) => {
        if (stateData.touchSwipeState) {
          stateData.touchSwipeState = false;
          doChangeProgress(renderData.transLeft);
        }
      },
    }
  );
};
const handleSelectBoxClick = (e) => {
  if (props.disableChangeProgress) {
    return;
  }
  const { width, left } = refAudioWaveform.value.getBoundingClientRect();
  const clientX = e.clientX;
  emits("changeProgress", (clientX - left) / width);
};

const initResizeObserve = () => {
  renderData.resizeObserver = new ResizeObserver(() => {
    nextTick(() => {
      if (refAudioWaveform.value) {
        let waveBoxInfo = refAudioWaveform.value.getBoundingClientRect();
        if (waveBoxInfo.width != renderData.width && waveBoxInfo.width > 0) {
          init();
        }
      }
    });
  });
  renderData.resizeObserver.observe(document.body);
};

watch(
  () => props.waveformData,
  () => {
    if (refAudioWaveform.value) {
      let waveBoxInfo = refAudioWaveform.value?.getBoundingClientRect();
      if (waveBoxInfo.width > 0) {
        init();
      }
    }
  }
);
watch(
  () => props.progress,
  (newVal) => {
    renderData.progress = props.progress;
  }
);

watch(
  () => {
    return renderData.progress;
  },
  () => {
    setProgress(renderData.progress);
  }
);

const changeUrlListenerListener = () => {
  renderData.isCurPlay = GlobalAudioPlayer.currentUrl == props.url;
};
onMounted(async () => {
  GlobalAudioPlayer.registGlobleListener(
    "curPlayUrlChange",
    changeUrlListenerListener
  );
  changeUrlListenerListener();
  initConfig();
  if (refAudioWaveform.value) {
    let waveBoxInfo = refAudioWaveform.value?.getBoundingClientRect();
    if (waveBoxInfo.width > 0) {
      await init();
    }
  }
  setTimeout(() => {
    initResizeObserve();
  }, 20);
  // initEvent();
});
onBeforeUnmount(() => {
  stateData.registTouchWaveBarSwipeCall?.();
});
onUnmounted(() => {
  renderData.resizeObserver?.disconnect();
  GlobalAudioPlayer.releaseGlobleListener(
    "curPlayUrlChange",
    changeUrlListenerListener
  );
});
</script>
<style lang="scss">
@keyframes audio-waveform-bar-animation {
  0% {
    transform: scaleY(1);
    opacity: 1;
  }
  50% {
    transform: scaleY(5);
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
  }
}
.audio-waveform {
  width: 100%;
  height: 100%;
  position: relative;
  .audio-waveform-loading {
    text-align: center;
    .audio-waveform-loading-wave-bar {
      width: 2px;
      height: 2px;
      background: #d282ff;
      margin: 0 5px;
      animation: audio-waveform-bar-animation 0.5s infinite;
      animation-timing-function: cubic-bezier(0.43, 0.04, 0.51, 0.94);
    }
    @for $i from 1 through 8 {
      .audio-waveform-loading-wave-bar:nth-child(#{$i}) {
        animation-delay: #{($i - 1) * 0.05}s;
      }
    }
  }
  .audio-waveform-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .audio-waveform-item {
      width: var(--wavebarItemWidth);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 2px;
      &.audio-waveform-item-active {
        background: #d282ff;
      }
    }
  }
  .audio-waveform-op {
    position: absolute;
    width: 0px;
    height: 100%;
    left: var(--progressTransLateX);
    top: 0;
    transform: var(--progressTrasform);
    transform-origin: center center;
    .audio-waveform-op-progress {
      position: absolute;
      width: 2px;
      height: 100%;
      background: var(--progressColor);
      left: -1px;
      top: 0;
      transform: var(--progressTrasform);
    }
    .audio-waveform-op-progress-hover {
      position: absolute;
      width: 12px;
      height: 100%;
      background: transparent;
      left: -6px;
      top: 0;
      display: none;
      &:hover {
        background: var(--progressHoverColor);
      }
    }
  }
}
</style>
