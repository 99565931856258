import { defineStore } from "pinia"
import apiMine from "@/api/api/apiMine.js"
import useGlobalPlayDataStore from "@/store/globalPlayDataStore.js"
import layoutConfig from "@/config/layoutConfig"

const useMineStore = defineStore({
  id: "mineStore",
  state: () => {
    return {
      isMobile: layoutConfig.renderType == "mobile",
      list: [],
      loadingMore: false,
      isLoading: false,
      loadError: false,
      finished: false,
      lastId: 0,
      total: null,
    }
  },
  actions: {
    // 上拉加载
    async loadMore(done, query) {
      this.isLoading = true
      const { data, code, msg } = await apiMine.mineFavoriteList({
        t: 2,
        size: 20,
        ...query,
        ...(this.lastId ? { last_id: this.lastId } : {}),
      })
      this.isLoading = false
      if (code == 200) {
        const globalPlayDataStore = useGlobalPlayDataStore()
        if (data?.list) {
          this.loadError = false
          this.list = [...this.list, ...data?.list]
          this.lastId = data?.last_id ? data?.last_id : 0
          console.log("this.lastId", this.lastId)
          this.total = data?.other_total
          done(this.list, false)
          const useData = this.list.map((item) => item.song)
          globalPlayDataStore.addPlayDatas(useData)
        } else {
          done(this.list, true)
        }
      } else {
        this.loadError = true
        done(this.list, true)
      }
    },
    // 重新加载
    async doRetry() {
      this.isLoading = true
      let { code, data, msg } = await apiMine.mineFavoriteList({
        size: 20,
        t: 2,
      })
      this.isLoading = false
      if (code == 200) {
        this.loadError = false
        if (data.list) {
          const globalPlayDataStore = useGlobalPlayDataStore()
          this.list = data?.list
          this.lastId = data?.last_id ? data?.last_id : 0
          this.total = data?.other_total
          const useData = this.list.map((item) => item.song)
          globalPlayDataStore.addPlayDatas(useData)
        } else {
          this.list = []
          this.total = 0
          this.finished = true
        }
      } else {
        this.total = 0
        this.isLoading = false
        this.loadError = true
      }
    },
    // 下拉刷新
    async refresh(done, query) {
      const globalPlayDataStore = useGlobalPlayDataStore()
      this.isLoading = true
      this.lastId = 0
      let { code, data, msg } = await apiMine.mineFavoriteList({
        t: 2,
        size: 20,
        ...query,
      })
      if (code == 200) {
        if (data.list) {
          console.log("data.list", data.list)
          this.list = data?.list
          this.loadError = false
          this.isLoading = false
          this.lastId = data?.last_id ? data?.last_id : 0
          this.total = data?.other_total
          done(this.list, false)
          const useData = this.list.map((item) => item.song)
          globalPlayDataStore.addPlayDatas(useData)
        } else {
          this.list = []
          this.total = 0
          this.finished = true
          done(this.list, true)
        }
      } else {
        this.total = 0
        done(this.list, true)
        this.loadError = true
      }
    },
    // 更新列表数据
    updateShopList(item) {
      let index = this.list.findIndex((itemF) => itemF.id === item.id)
      if (index > -1) {
        Object.assign(this.list[index], item)
        this.list = [].concat(this.list)
      }
    },
    // 删除列表数据
    deleteShopList(item) {
      let index = this.list.findIndex((itemF) => itemF.song.song_id === item.song_id)
      if (index > -1) {
        this.list.splice(index, 1)
        this.total -= 1
      }
      // const useData = this.list?.map((item2) => item2?.song) ?? []
      // const globalPlayDataStore = useGlobalPlayDataStore()
      // let res = item
      // globalPlayDataStore.addPlayDatas(useData)
      // res.song.favorite_state = 555
      // globalPlayDataStore.updatePlayData(res.song)
    },
    // 给全局播放器提供的点赞更新状态
    updateShopLike(song_id, favorite_state) {
      let index = this.list.findIndex((itemF) => itemF.song.song_id === song_id)
      if (index > -1) {
        const item = this.list[index].song
        Object.assign(this.list[index].song, {
          ...item,
          favorite_state,
        })
        this.list = [].concat(this.list)
      }
    },
    // 给全局播放器提供的购物车更新状态
    updateShopCart(song_id, cart_state) {
      let index = this.list.findIndex((itemF) => itemF.song.song_id === song_id)
      if (index > -1) {
        const item = this.list[index].song
        Object.assign(this.list[index].song, {
          ...item,
          cart_state,
        })
        this.list = [].concat(this.list)
      }
    },
    addPlayList() {
      const useData = this.list.map((item) => item.song)
      const globalPlayDataStore = useGlobalPlayDataStore()
      globalPlayDataStore.setCurPlayGroup("storeList")
      globalPlayDataStore.playlist = []
      globalPlayDataStore.addPlayDatas(useData)
    },
    // 播放器
    togglePlay(item) {
      const useData = this.list.map((item) => item.song)
      const globalPlayDataStore = useGlobalPlayDataStore()
      globalPlayDataStore.setCurPlayGroup("likedList")
      console.log("useData", useData)
      globalPlayDataStore.setPlayDatas(useData, item.song)
    },
    // 更新状态
    updateShopOrderState(song_id, order_state) {
      let index = this.list.findIndex((itemF) => itemF?.song?.song_id === song_id)
      if (index > -1) {
        const item = this.list[index].song
        Object.assign(this.list[index].song, {
          ...item,
          order_state,
        })
        this.list = [].concat(this.list)
      }
    },
    // 切换够买状态
    changeSaleState(song_id, state) {
      let index = this.list.findIndex((itemF) => itemF?.song?.song_id === song_id)
      if (index > -1) {
        const item = this.list[index].song
        Object.assign(this.list[index].song, {
          ...item,
          sold_state: state,
        })
        this.list = [].concat(this.list)
      }
    },
  },
})

export default useMineStore
