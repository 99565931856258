import { defineStore } from "pinia";
import { nextTick } from "vue";
import { urlIncludeDomain } from "@/utils/tools.js";
import { $$language, $$t } from "@/i18n/i18n.js";
import message from "@/components/functionCallComponent/message/message.js";
// import config from "@/config/config.js";
import useDraftDataStore from "./draftDataStore.js";
import useLyricDataStore from "./lyricDataStore.js";
// import useMuseDataStore from "./museDataStore.js";
import userReferMusicStore from "./referMusicStore.js";
import userVocalMusicStore from "./vocalMusicStore.js";
import userMotifDataStore from "./motifDataStore.js";
import usePromptDataStore from "@/store/create/promptDataStore.js";
import useGlobalPlayDataStore from "@/store/globalPlayDataStore.js";
// const draftDataStore = useDraftDataStore();
// const lyricDataStore = useLyricDataStore();
// const museDataStore = useMuseDataStore();
// const referMusicStore = userReferMusicStore();
// const vocalMusicStore = userVocalMusicStore();
// const motifDataStore = userMotifDataStore();
// const promptDataStore = usePromptDataStore();

const useMuseDataStore = defineStore({
  id: "museDataStore",
  state: () => {
    return {
      timescamp: 0,
      referenceData: null,
      motifData: null,
      vocalData: null,
      promptData: null,
      museLeadDataType_refer: false,
      museLeadDataType_vocal: false,
      museLeadDataType_motif: false,
      museLeadDataType_prompt: false,
      museLeadDataType: [],
    };
  },
  actions: {
    initMuseData(data) {
      const referMusicStore = userReferMusicStore();
      const motifDataStore = userMotifDataStore();
      const vocalMusicStore = userVocalMusicStore();
      const promptDataStore = usePromptDataStore();
      referMusicStore.initReferMusic(data);
      motifDataStore.initMotifData(data);
      vocalMusicStore.initReferMusic(data);
      promptDataStore.initPromptData(data);
      this.resetMuseLeadDataType();
      if (data.refer || data.vocal) {
        if (data.refer) {
          this.museLeadDataType_refer = true;
          this.museLeadDataType.push("refer");
        }
        if (data.vocal) {
          this.museLeadDataType_vocal = true;
          this.museLeadDataType.push("vocal");
        }
      } else if (data.prompt) {
        if (data.prompt) {
          this.museLeadDataType_prompt = true;
          this.museLeadDataType.push("prompt");
        }
      } else {
        if (data.motif) {
          this.museLeadDataType_motif = true;
          this.museLeadDataType.push("motif");
        }
      }
      nextTick().then(() => {
        this.timescamp++;
      });
    },
    resetMuseLeadDataType() {
      this.museLeadDataType_refer = false;
      this.museLeadDataType_vocal = false;
      this.museLeadDataType_motif = false;
      this.museLeadDataType_prompt = false;
      this.museLeadDataType = [];
    },
    getMuseData() {
      const referMusicStore = userReferMusicStore();
      const motifDataStore = userMotifDataStore();
      const vocalMusicStore = userVocalMusicStore();
      const promptDataStore = usePromptDataStore();
      return {
        refer: referMusicStore.getReferMusic(),
        motif: motifDataStore.getMotifData(),
        vocal: vocalMusicStore.getReferMusic(),
        prompt: promptDataStore.getPromptData(),
        museLeadDataType: this.museLeadDataType,
      };
    },
    deleteMuseDataByAudit(feedData) {
      const referMusicStore = userReferMusicStore();
      const motifDataStore = userMotifDataStore();
      const vocalMusicStore = userVocalMusicStore();
      const promptDataStore = usePromptDataStore();
      motifDataStore.deleteMotifDataByAudit(feedData?.motif);
      vocalMusicStore.deleteCurUseMusicByAudit(feedData?.vocal);
      referMusicStore.deleteCurUseMusicByAudit(feedData?.refer);
      promptDataStore.deletePromptDataByAudit(feedData?.prompt);
    },
    getMuseGenerateData() {
      const referMusicStore = userReferMusicStore();
      const motifDataStore = userMotifDataStore();
      const vocalMusicStore = userVocalMusicStore();
      const promptDataStore = usePromptDataStore();
      if (this.museLeadDataType_refer || this.museLeadDataType_vocal) {
        return {
          ...(this.museLeadDataType_refer
            ? {
              refer: referMusicStore.getReferMusic(),
            }
            : {}),
          ...(this.museLeadDataType_vocal
            ? {
              vocal: vocalMusicStore.getReferMusic(),
            }
            : {}),
          museLeadDataType: this.museLeadDataType,
        };
      } else if (this.museLeadDataType_prompt) {
        return {
          prompt: promptDataStore.getPromptData(),
          museLeadDataType: this.museLeadDataType,
        };
      } else {
        if (this.museLeadDataType_motif) {
          return {
            motif: motifDataStore.getMotifData(),
            museLeadDataType: this.museLeadDataType,
          };
        }
      }
      return {};
    },
    setReferenceData(data) {
      this.referenceData = data;
      if (!data && this.museLeadDataType_refer) {
        this.changeMuseLeadDataType("refer", false);
      }
      nextTick().then(() => {
        this.timescamp++;
      });
    },
    setMotifData(data) {
      this.motifData = data;
      if (!data && this.museLeadDataType_motif) {
        this.changeMuseLeadDataType("motif", false);
      }
      nextTick().then(() => {
        this.timescamp++;
      });
    },
    setVocalData(data) {
      this.vocalData = data;
      if (!data && this.museLeadDataType_vocal) {
        this.changeMuseLeadDataType("vocal", false);
      }
      nextTick().then(() => {
        this.timescamp++;
      });
    },
    setPromptData(data) {
      this.promptData = data;
      if (!data?.content && this.museLeadDataType_prompt) {
        this.changeMuseLeadDataType("prompt", false);
      }
      nextTick().then(() => {
        this.timescamp++;
      });
    },
    updataReferenceData(data) {
      const referMusicStore = userReferMusicStore();
      referMusicStore.setCurUseMusic(data, true);
      nextTick().then(() => {
        this.timescamp++;
      });
    },
    updataMotifData(data) {
      const motifDataStore = userMotifDataStore();
      motifDataStore.setMotifData(data, true);
      nextTick().then(() => {
        this.timescamp++;
      });
    },
    updataVocalData(data) {
      const vocalMusicStore = userVocalMusicStore();
      vocalMusicStore.setCurUseMusic(data, true);
      nextTick().then(() => {
        this.timescamp++;
      });
    },
    updataPromptData(data) {
      const promptDataStore = usePromptDataStore();
      promptDataStore.setCurPromptData(data, true);
      nextTick().then(() => {
        this.timescamp++;
      });
    },
    deleteReferenceData() {
      const referMusicStore = userReferMusicStore();
      referMusicStore.setCurUseMusic(null);
      nextTick().then(() => {
        this.timescamp++;
      });
    },
    deleteMotifData() {
      const motifDataStore = userMotifDataStore();
      motifDataStore.deleteMotifData(null);
      nextTick().then(() => {
        this.timescamp++;
      });
    },
    deleteVocalData() {
      const vocalMusicStore = userVocalMusicStore();
      vocalMusicStore.setCurUseMusic(null);
      nextTick().then(() => {
        this.timescamp++;
      });
    },
    deletePromptData() {
      const promptDataStore = usePromptDataStore();
      promptDataStore.setCurPromptData(null);
      nextTick().then(() => {
        this.timescamp++;
      });
    },
    changeMuseLeadDataType(type, isUse = true) {
      this[`museLeadDataType_${type}`] = isUse;
      this.museLeadDataType = [];
      if (type == "refer" || type == "vocal") {
        if (this.museLeadDataType_refer) {
          this.museLeadDataType.push("refer");
        }
        if (this.museLeadDataType_vocal) {
          this.museLeadDataType.push("vocal");
        }
        if (this.museLeadDataType_motif || this.museLeadDataType_prompt) {
          if (this.museLeadDataType_motif) {
            message.info({
              position: "top",
              content: $$t("create.refer_mutual_exclusion_rule_motif"),
              duration: 3000,
            });
          } else {
            message.info({
              position: "top",
              content: $$t("create.refer_mutual_exclusion_rule_prompt"),
              duration: 3000,
            });
          }
          this.museLeadDataType_motif = false;
          this.museLeadDataType_prompt = false;
        }
      } else if (type == "prompt") {
        this.museLeadDataType = [type];
        if (
          this.museLeadDataType_refer ||
          this.museLeadDataType_vocal ||
          this.museLeadDataType_motif
        ) {
          // message.info({
          //   position: "top",
          //   content: $$t("create.refer_mutual_exclusion_rule"),
          //   duration: 3000,
          // });
          message.info({
            position: "top",
            content: $$t("create.refer_mutual_exclusion_rule_prompt"),
            duration: 3000,
          });
          this.museLeadDataType_refer = false;
          this.museLeadDataType_vocal = false;
          this.museLeadDataType_motif = false;
        }
      } else if (type == "motif") {
        this.museLeadDataType = [type];
        if (
          this.museLeadDataType_refer ||
          this.museLeadDataType_vocal ||
          this.museLeadDataType_prompt
        ) {
          // message.info({
          //   position: "top",
          //   content: $$t("create.refer_mutual_exclusion_rule"),
          //   duration: 3000,
          // });
          message.info({
            position: "top",
            content: $$t("create.refer_mutual_exclusion_rule_motif"),
            duration: 3000,
          });
          this.museLeadDataType_refer = false;
          this.museLeadDataType_vocal = false;
          this.museLeadDataType_prompt = false;
        }
      } else {
        this.museLeadDataType = [type];
      }
    },
  },
});

export default useMuseDataStore;
