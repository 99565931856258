import axiosIS from "@/api/net/axios.js";
import { getGuidanceJsonDataUrl } from "@/config/config.js";
import ApiConfig, { baseUrl } from "@/config/apiConfig.js";
import useUserDataStore from "@/store/userDataStore.js";
import { getObjectStorageFullUrl } from "@/lib/objectStorage/objectStorage.js";
import { $$t, $$language } from "@/i18n/i18n.js";
import { isProd } from "@/utils/tools.js";
import usePresetSourceDataStore from "@/store/create/presetSourceDataStore.js";


export default {
  // https://rg975ojk5z.feishu.cn/wiki/P87cwAaMQiWBCvkULaxc7nNznqc
  getDraftData(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/feed/draft`, data, { method: "get" });
  },

  // https://rg975ojk5z.feishu.cn/wiki/A7U6w85FEicPF9kqNAqczR0QnUg
  saveLyrics(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/lyrics/save`, data, { method: "post" });
  },
  // https://rg975ojk5z.feishu.cn/wiki/HuRDwFweoioU5EkytN7cyNKYn6b
  generateLyrics(data = {}) {
    const userDataStore = useUserDataStore();
    const presetSourceDataStore = usePresetSourceDataStore();

    // if (data.type == 4 && !userDataStore.isLogin) {
    //   return new Promise((resolve, reject) => {
    //     presetSourceDataStore
    //       .getPresetSourceData()
    //       .then((data) => {
    //         let lyricDatas = data?.lyrics ?? [];
    //         const randomNumber = Math.floor(Math.random() * lyricDatas.length); // 获取 JSON 数组的随机索引
    //         const useData = lyricDatas[randomNumber] ?? null;
    //         if (useData) {
    //           resolve({
    //             code: 200,
    //             data: {
    //               isTemplate: true,
    //               title: useData.title,
    //               lyrics: useData.lyrics,
    //             }
    //           })
    //         } else {
    //           reject({
    //             code: 500,
    //             message: 'get data null',
    //           })
    //         }
    //       })
    //       .catch((error) => {
    //         reject({
    //           code: 500,
    //           message: error.message,
    //         });
    //       });
    //   });
    // } else {
    return axiosIS(`${baseUrl}/api/pgc/lyrics/generate`, data, {
      method: "post",
    });
    // }
  },
  getSongAuditState(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/song/check`, data, {
      method: "get",
    });
  },

  // https://rg975ojk5z.feishu.cn/wiki/O3ulwZHSciNiIIkjchncdctTn2e
  getFeedList(data = {}, url) {
    return axiosIS(
      `${baseUrl}/api/pgc/feed/list`,
      //url??`${baseUrl}/api/pgc/feed/list`,
      data,
      { method: "get" }
    );
  },
  getFilterFeedList(data = {}, url) {
    return axiosIS(
      `${baseUrl}/api/pgc/feed/list/search`,
      //url??`${baseUrl}/api/pgc/feed/list`,
      data,
      { method: "get" }
    );
  },
  saleManageSong(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/song/sale-manage/apply-or-stop`, data, {
      method: "post",
    });
  },
  shareSongReport(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/share/report`, data, {
      method: "post",
    });
  },
  saveSongTitle(data) {
    return axiosIS(`${baseUrl}/api/pgc/song/rename`, data, {
      method: "post",
    });
  },
  favoriteSong(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/user/song/favorite`, data, {
      method: "post",
    });
  },
  // https://rg975ojk5z.feishu.cn/wiki/CepdwmhTgiy0CnkwjQAc34VtnDg
  doGenerate(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/feed/generate`, data, {
      method: "post",
    });
  },

  feedCardDelete(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/song/delete`, data, {
      method: "post",
    });
  },

  reEditCurFeedDraft(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/feed/restore`, data, {
      method: "post",
    });
  },
  feedCardRetryGenerate(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/feed/generate/retry`, data, {
      method: "post",
    });
  },
  feedCardCancelGenerate(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/feed/generate/cancel`, data, {
      method: "post",
    });
  },
  feedCardDeleteGenerate(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/feed/delete`, data, {
      method: "post",
    });
  },
  feedCardEditedReGenerate(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/feed/regenerate`, data, {
      method: "post",
    });
  },
  // feedCardGenerateSimilar(data = {}) {
  //   return axiosIS(`${baseUrl}/api/ugc/feed/generate/similar`, data, {
  //     method: "post",
  //   });
  // },
  feedCardDownloadQuery(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/song/download`, data, {
      method: "post",
    });
  },
  feedCardGetUsertInfo(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/reality`, data, {
      method: "get",
    });
  },
  feedCardGetDowloadWAVAndStem(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/check/download`, data, {
      method: "get",
    });
  },
  getMyFavoriteSongs(data = {}) {
    return new Promise((resolve, reject) => {
      axiosIS(`${baseUrl}/api/pgc/user/favorite/songs`, data, {
        method: "get",
      })
        .then((res) => {
          if (res.code == 200) {
            let data = res.data;
            let map = {};
            data?.list?.forEach((item) => {
              map[item.song?.song_id] = item;
            });
            data?.liked_list?.forEach((item) => {
              item.songs.forEach((song) => {
                if (map[song.song_id]) {
                  song.wave_list = map[song.song_id]?.song.wave_list;
                }
              });
            });
            resolve({
              code: res.code,
              msg: res.msg,
              data: {
                list: data.liked_list,
                total: data.my_total,
                last_id: data.last_id,
                more: !!data.last_id,
              },
            });
          } else {
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getMyPublishedSongs(data = {}) {
    return new Promise((resolve, reject) => {
      axiosIS(`${baseUrl}/api/pgc/user/published/songs`, data, {
        method: "get",
      })
        .then((res) => {
          if (res.code == 200) {
            let data = res.data;
            resolve({
              code: res.code,
              msg: res.msg,
              data: {
                list: data.list,
                total: data.total,
                last_id: data.last_id,
                more: !!data.last_id,
              },
            });
          } else {
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEasyModePresetData(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/prompt/ez-mode/list`, data, {
      method: "get",
    });
  },
  getPromptPresetData(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/prompt/tags`, data, {
      method: "post",
    });
  },
  changebatchPromptPresetData(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/prompt/tags/switch`, data, {
      method: "post",
    });
  },
  changeRecommendPromptPresetData(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/prompt/tags/recommend`, data, {
      method: "post",
    });
  },
  getPromptRandomData(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/prompt/random`, data, {
      method: "get",
    });
  },

  doReportPlayedState(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/song/palyed/report`, data, {
      method: "post",
    });
  },
  doPlayStateReport(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/song/play/report`, data, {
      method: "post",
    })
  },
  feedSongPublish(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/song/publish`, data, {
      method: "post",
    });
  },

  savefeedSongInfo(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/song/modify`, data, {
      method: "post",
    })
  },
  getPresetSourceData(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/resource/preset`, data, {


    })
  }
};
