<template>
  <div>
    <Dialog
      :customClass="`${createRenderType}-invite-dialog`"
      width="620px"
      background="linear-gradient(129deg, #1A3C8D 0%, #695BC5 47%, #3A1B6F 100%)"
      :backgroundImage="false"
      :visible="popupVisible"
      :hideCloseBtn="createRenderType == 'mobile' ? true : false"
      :showBottomCloseBtn="createRenderType == 'mobile' ? true : false"
      @close="onCloseClick"
    >
      <div :class="`${createRenderType}-invite-popup flex-column-center`">
        <div class="title">Invite Friends, Get Free Credits!</div>
        <div class="step">
          <p>1. Share your invite link with your friends</p>
          <p>2. They sign in for Mureka through your invite link</p>
          <p>3. You'll receive credits for free songs</p>
        </div>
        <div class="keep">
          Keep inviting friends and earn 2 credits for each one who joins! Your invite link：
        </div>
        <div class="flex copy-btn">
          <div class="link olh">{{ link }}</div>
          <div
            class="copy pointer"
            @click="handleCopyLink"
            @mouseleave="handleMouseLeave"
            @touchend="handleMouseLeave"
          >
            {{ btnText }}
            <div class="tip" v-if="showTip">
              Your invite link has been copied! <br />
              Share it with your friends
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, defineProps, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { $$language } from "@/i18n/i18n";
import config from "@/config/config";
import { copyToClipboard } from "@/utils/tools";
import { generateInviteCode } from "@/api/api/apiCredit.js";
import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
import Dialog from "@/components/basic/dialog/Dialog.vue";

const createLayoutStore = useCreateLayoutStore();
const { createRenderType } = storeToRefs(createLayoutStore);
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  remove: {
    type: Function, //传入移除节点方法,这里是createApp中的方法
    default: null,
  },
});

const popupVisible = ref(props.visible);
const showTip = ref(false);
const link = ref("");
const btnText = ref("Copy");
let timer = null;
let btnTimer = null;

watch(
  () => popupVisible.value,
  (val) => {
    !val && props.remove();
    if (!val) {
      timer && clearTimeout(timer);
      btnTimer && clearTimeout(btnTimer)
    }
  }
);

watch(
  () => showTip.value,
  (val) => {
    if (val) {
      timer = setTimeout(() => {
        showTip.value = false;
        clearTimeout(timer);
      }, 3000);
    }
  }
);

const onCloseClick = () => {
  popupVisible.value = false;
};

const changeBtnShowText = () => {
  btnText.value = "Copied";
  btnTimer = setTimeout(() => {
    btnText.value = "Copy";
    clearTimeout(btnTimer);
  }, 800);
};

const handleCopyLink = () => {
  copyToClipboard(link.value).then((res) => {
    showTip.value = true;
  });
  changeBtnShowText();
  firebaseUtils.logClickEvent("invite_link_popup_copy", {});
};

const handleMouseLeave = () => {
  showTip.value = false;
};

const getCode = () => {
  generateInviteCode({}).then((res) => {
    if (res.code == 200) {
      link.value = `${config.baseOrigin}?invite_code=${res?.data?.invite_code}`;
    }
  });
};

onMounted(() => {
  getCode();
  firebaseUtils.logViewEvent("invite_link_popup", {});
});
</script>

<style lang="scss" scoped>
.pc-invite-popup {
  padding: 0 50px 50px;
  color: #fff;
  .title {
    padding-top: 4px;
    font-size: 36px;
    font-weight: bold;
    line-height: 48px;
    text-align: center;
    word-wrap: break-word;
  }
  .step {
    margin-top: 34px;
    font-size: 18px;
    font-weight: bold;
    line-height: 32px;
  }
  .keep {
    margin-top: 34px;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }
  .copy-btn {
    width: 100%;
    margin-top: 32px;
    .link {
      flex: 1;
      padding: 16px 20px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.2);
      font-size: 18px;
      font-weight: bold;
      line-height: 28px;
    }
    .copy {
      position: relative;
      width: 120px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      border-radius: 8px;
      background: #94adff;
      font-size: 18px;
      font-weight: 500;
      margin-left: 12px;
      color: #000;
      .tip {
        position: absolute;
        top: calc(-100% - 20px);
        left: 50%;
        transform: translateX(-50%);
        padding: 10px 12px;
        background: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #fff;
        width: max-content;
        border-radius: 8px;
        &::after {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateY(100%) translateX(-50%);
          content: "";
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 8px solid #000;
        }
      }
    }
  }
}

.mobile-invite-popup {
  position: relative;
  color: #fff;
  padding: 0 24px 24px;
  .title {
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
    word-wrap: break-word;
  }
  .step {
    margin-top: 14px;
    font-size: 12px;
    font-weight: bold;
    line-height: 26px;
    text-align: center;
  }
  .keep {
    margin-top: 16px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
  .copy-btn {
    width: 100%;
    flex-direction: column;
    margin-top: 24px;
    .link {
      padding: 0px 20px;
      height: 48px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.2);
      font-size: 14px;
      font-weight: bold;
      line-height: 48px;
    }
    .copy {
      position: relative;
      margin-top: 24px;
      padding: 12px 0;
      border-radius: 62px;
      background: linear-gradient(100deg, #4fdeff 0%, #e675ff 99%);
      font-size: 14px;
      font-weight: 500;
      color: #000;
      text-align: center;
      .tip {
        position: absolute;
        top: 0;
        top: calc(-100% - 38px);
        left: 50%;
        transform: translateX(-50%);
        padding: 10px 12px;
        background: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #fff;
        width: max-content;
        border-radius: 8px;
        &::after {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateY(100%) translateX(-50%);
          content: "";
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 8px solid #000;
        }
      }
    }
  }
  .close-btn {
    position: absolute;
    bottom: 0;
    transform: translateY(calc(100% + 26px));
    z-index: 100;
    img {
      width: 40px;
      height: 40px;
    }
  }
}
</style>