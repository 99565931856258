<template>
  <Teleport to="body">
    <div
      :data-dialog-tag="tag"
      class="dialog custom-dialog"
      :class="`${renderData.renderClass} ${props.customClass} ${
        props.visible ? 'dialog-show' : 'dialog-hide'
      }`"
      @click="onMaskClick"
    >
      <div
        class="dialog-box flex-column"
        :class="{
          'dialog-box-done': props.doneText != '',
        }"
        :style="{
          width: props.width,
          height: props.height,
          ...(!props.backgroundImage
            ? {
                background: props.background,
              }
            : { backgroundImage: props.backgroundImage }),
          backgroundSize: 'cover',
        }"
      >
        <div class="dialog-close btn tc" v-if="props.hideCloseBtn !== true">
          <IconButton
            size="40px"
            icoSize="20px"
            :disable="props.disableClose"
            @onClick="onCloseClick"
            :backgroundColor="
              renderData.renderClass == 'drawer-dialog'
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(255, 255, 255, 0)'
            "
            :hoverBackgroundColor="
              renderData.renderClass == 'drawer-dialog'
                ? 'rgba(255, 255, 255, 0.15)'
                : 'rgba(255, 255, 255, 0.1)'
            "
          >
            <img src="../../../assets/img/close-ico.png" />
          </IconButton>
        </div>
        <div class="dialog-done btn tc" v-if="props.doneText != ''">
          <Button
            @click.stop="onDoneClick"
            :renderStyle="{
              '--padding': '0',
              '--width': '63px',
              '--height': '28px',
              '--borderRadius': '50px',
              '--background': 'rgba(255, 255, 255, 0.2)',
              '--fontColor': '#fff',
              '--fontSize': '14px',
              '--fontWeight': 'normal',
              '--border': 'none',
            }"
          >
            {{ props.doneText }}
          </Button>
        </div>
        <h1
          class="dialog-header"
          v-if="props.title"
          :class="
            props.titleAlign === 'left'
              ? 'tl dialog-header-tl'
              : 'tc dialog-header-tc'
          "
        >
          <slot name="header">
            {{ props.title }}
          </slot>
        </h1>
        <h1
          class="dialog-subtitle"
          v-if="props.subTitle || $slots.subtitle"
          :class="{
            tl: props.titleAlign == 'left',
            tc: props.titleAlign != 'left',
            dsn: !props.subTitle && !$slots.subtitle,
          }"
        >
          <slot name="subtitle">
            {{ props.subTitle }}
          </slot>
        </h1>
        <div
          class="content-top-margin"
          v-if="props.subTitle || $slots.subtitle || props.title"
        ></div>
        <div class="dialog-content flex1">
          <slot></slot>
        </div>
        <div class="bottom-close-btn" v-if="showBottomCloseBtn === true" @click="onCloseClick">
        <img src="@/assets/img/credit/close-btn.png" />
      </div>
      </div>
    </div>
  </Teleport>
</template>
<script setup>
import {
  defineProps,
  defineEmits,
  onMounted,
  onUnmounted,
  watch,
  reactive,
} from "vue";
import IconButton from "@/components/basic/button/IconButton.vue";
import Button from "@/components/basic/button/Button.vue";

import { addDialog, removeDialog } from "./dialog.js";
import { getUuid } from "@/utils/tools";
import bgImg from "@/assets/img/dialog-bg-1.webp";

const props = defineProps({
  tag: String,
  customClass: {
    type: String,
    default: "",
  },
  maskClose: {
    type: Boolean,
    default: false,
  },
  doneText: {
    type: String,
    default: "",
  },
  hideCloseBtn: {
    type: Boolean,
    default: false,
  },
  showBottomCloseBtn: {
    type: Boolean,
    default: false,
  },
  disableClose: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: "unset",
  },
  height: {
    type: String,
    default: "unset",
  },
  titleAlign: {
    type: String,
    default: "center",
  },
  title: String,
  subTitle: String,
  visible: {
    type: Boolean,
    default: false,
  },

  background: {
    type: String,
    default:
      window.innerWidth <= 763
        ? "background: rgba(71, 74, 74, 0.5)"
        : "transparent",
  },
  backgroundImage: {
    type: String,
    default: window.innerWidth <= 763 ? null : `url(${bgImg})`,
  },
  mobileRenderType: {
    type: String,
    default: "dialog", //"drawerDialog",
  },
});
const tag = props.tag ?? getUuid();
const emits = defineEmits(["close", "done"]);

const winWidth = ref(window.innerWidth);

const renderData = reactive({
  resizeObserver: null,
  renderClass:
    props.mobileRenderType == "drawerDialog" && winWidth.value <= 768
      ? "drawer-dialog"
      : "",
});

const onResize = () => {
  winWidth.value = window.innerWidth;
  renderData.renderClass =
    props.mobileRenderType == "drawerDialog" && winWidth.value <= 768
      ? "drawer-dialog"
      : "";
};

const onCloseClick = () => {
  if (props.disableClose) {
    return;
  }
  emits("close", false);
};
const onMaskClick = () => {
  if (props.maskClose) {
    onCloseClick();
  }
};
const onDoneClick = () => {
  emits("done");
};
const close = () => {
  emits("close", false);
};
onMounted(() => {
  if (props.visible) {
    addDialog(tag, close);
  }
  renderData.resizeObserver = new ResizeObserver(onResize).observe(
    document.body
  );
});

watch(
  () => {
    return props.visible;
  },
  (newVal) => {
    if (props.visible) {
      addDialog(tag, close);
    } else {
      removeDialog(tag);
    }
  }
);

onUnmounted(() => {
  removeDialog(tag);
  renderData.resizeObserver?.disconnect();
});
</script>
<style lang="scss">
.custom-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  &.dialog-show {
    display: flex;
  }
  &.dialog-hide {
    display: none;
  }
  .dialog-box {
    padding: 40px 0 0 0;
    background: #545a7d;
    border-radius: 16px;
    position: relative;
    margin: 20px;
    box-sizing: border-box;
    max-width: calc(100% - 40px);
    max-height: calc(100% - 40px);
  }

  .dialog-close {
    width: 36px;
    height: 36px;
    line-height: 36px;
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 101;
  }
  .dialog-header {
    font-size: 20px;
    font-family: HarmonyOS Sans SC;
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
    color: #ffffff;
    padding: 0 40px;
  }
  .dialog-subtitle {
    margin-top: 6px;
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.5);
    padding: 0 40px;
    &.dsn {
      display: none;
    }
  }
  .content-top-margin {
    height: 7px;
  }
  .dialog-content {
    overflow: auto;
  }
  .dialog-box-done {
    padding: 18px 0 0 0;
    .dialog-close {
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .dialog-done {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
.dialog.loading {
  .dialog-box {
    .dialog-subtitle,
    .content-top-margin {
      display: none;
    }
  }
}
.drawer-dialog {
  display: block;
  .dialog-box {
    padding: 20px 0 0 0;
    background: rgba(71, 74, 74, 0.5) !important;
    backdrop-filter: blur(100px);
    max-height: calc(100% - 40px);
    border-radius: 32px 32px 0 0;
    position: fixed;
    margin: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100% !important;
    box-sizing: border-box;
    max-width: 100% !important;
    max-height: calc(100% - 40px);
  }
  .dialog-subtitle {
    padding: 0 16px;
  }
  .dialog-close {
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .dialog-header {
    font-size: 20px;
    font-family: HarmonyOS Sans SC;
    font-size: 22px;
    font-weight: 600;
    line-height: 36px;
    color: #ffffff;
    padding: 0 60px 0 16px;
    text-align: left !important;
  }
  &.dialog-show {
    display: flex;
  }
  &.dialog-hide {
    display: none;
  }
}
.bottom-close-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(calc(100% + 20px));
  img {
    width: 40px;
    height: 40px;
  }
}
/* @media screen and (max-width: 768px) {
  .dialog {
    .dialog-box {
      padding: 0 20px 0px 20px;
    }
    .dialog-close {
      top: 5px;
      right: 5px;
    }
  }
}
@media screen and (max-height: 500px) {
  .dialog {
    .dialog-box {
      padding: 0 20px 0px 20px;
    }
    .content-top-margin {
      height: 0;
    }
    .dialog-subtitle {
      margin: 0;
      line-height: 20px;
    }
  }
}
@media screen and (max-width: 400px) {
  .dialog {
    .dialog-box {
      padding: 16px;
      margin: 0;
      max-width: calc(100% - 8px);
      max-height: calc(100% - 8px);
    }
    .dialog-subtitle {
      margin: 0;
      line-height: 20px;
    }
  }
} */
</style>
