import { defineStore } from "pinia";
import { ref, nextTick } from "vue";
import tools from "@/utils/tools.js";
import globalConfig from "@/config/config.js";
import feedListApi from "@/api/api/feedListApi.js";
import sseGenerateApi from "@/api/api/apiSse.js";
import message from "@/components/functionCallComponent/message.jsx";
import { getObjectStorageFullUrl } from "@/lib/objectStorage/objectStorage.js";
import globleConfig from "@/config/config.js";

import useDraftDataStore from "./draftDataStore.js";

const useLyricDataStore = defineStore({
  id: "lyricDataStore",
  state: () => {
    let datasC = {};
    try {
      const layoutData = window.localStorage.getItem(
        `${globleConfig.appname}_createLyricData`
      );
      datasC = JSON.parse(layoutData ?? "{}");
    } catch (e) {
      datasC = {};
    }
    return {
      title: "",
      lyric: "",
      lyricData: [],
      isLoading: false,
      curGenerateType: "",
      initTimescamp: 0,
      aiClickCountMap: datasC?.aiClickCountMap ?? {
        'randomLyrics': 0,
        'rowLyrics': 0,
        'allLyrics': 0,
      }
    };
  },
  actions: {
    cacheLayoutData() {
      window.localStorage.setItem(
        `${globleConfig.appname}_createLyricData`,
        JSON.stringify({
          aiClickCountMap: this.aiClickCountMap,
        })
      );
    },
    updateAiClickCount(
      type = 'randomLyrics',
      count
    ) {
      if (count) {
        this.aiClickCountMap[type] = count;
      } else {
        this.aiClickCountMap[type] += 1;
      }
      this.cacheLayoutData();
    },
    initLyricData(data, isReset = false) {
      this.title = data?.title ?? "";
      this.lyric = (data?.lyrics ?? "").slice(0, globleConfig.MAX_LYRIC_COUNT);
      if (isReset) {
        nextTick(() => {
          this.initTimescamp = new Date().getTime();
        });
      }
    },
    updateTitle(data) {
      this.title = data;
    },
    updateLyric(data) {
      this.lyric = data;
    },
    saveLyric() {
      const draftDataStore = useDraftDataStore();
      feedListApi
        .saveLyrics({
          title: this.title,
          lyrics: this.lyric,
          feed_id: draftDataStore.feed_id,
        })
        .then((res) => {
          const { code, data, msg } = res;
          if (code == 200) {
          }
        });
    },
    updateLyricCount(count) {
      this.lyricCharCount = count;
    },
    async getLyricData(data) {
      return {
        title: !this.title?.replace(/s+/g, "") ? null : this.title,
        lyrics: this.lyric,
      };
    },
  },
});
export default useLyricDataStore;
// export const LyricDataStore = useLyricDataStore()
