import { getGlobalOssClient, initOss, uploadFileToOss, abortUploadFileByOss } from './oss/oss-web.js'
import config from '@/config/config.js'
import { getUuid, getFileExtension, urlIncludeDomain } from '@/utils/tools.js';
import objectStorageApi from './objectStorageApi.js';

const curObjectStorageExpirTimeForwardTo = config.curObjectStorageExpirTimeForwardTo;

const CredentialMap = {
  oss: null
}

const CredentiaExpirTime = {

  getOssCred: () => {
    return new Promise((resolve, reject) => {
      if (CredentialMap.oss == null || (new Date().getTime() + curObjectStorageExpirTimeForwardTo) > (CredentialMap.oss.expired_timestamp * 1000)) {
        objectStorageApi.getOssCred().then((resOssCred) => {
          if (resOssCred.code == 200) {
            CredentialMap.oss = resOssCred.data;
            resolve(CredentialMap.oss)
          } else {
            reject(resOssCred.msg)
          }
        }).catch(e => {
          reject(e)
        })

      } else {
        resolve(CredentialMap.oss)
      }
    })

  },
}

export const initObjectStorageClient = initOss;
export const uploadFileToObjectStorage = (file, _callback = () => { }) => {
  return new Promise(async (resolve, reject) => {

    try {
      const resOssCred = await CredentiaExpirTime.getOssCred();
      const filename = getUuid() + '.' + file.name.split('.').pop();
      const pathKey = resOssCred.allow_prefix + filename;
      const fullUrl = config.baseObjectStorageUrl + pathKey;
      uploadFileToOss(file, pathKey, _callback, resOssCred).then(res => {
        resolve({
          pathKey: pathKey,
          fullUrl: fullUrl,
        });
      }).catch(e => {
        reject(e);
      })
    } catch (e) {
      reject(e)
    }
  });

}

export const getGlobalObjectStorageClient = getGlobalOssClient

export const abortUploadFileToObjectStorage = abortUploadFileByOss

export const getObjectStorageFullUrl = (url) => {
  if (urlIncludeDomain(url)) {
    return url;
  }
  return config.baseObjectStorageUrl + url;
}

export default {
  initObjectStorageClient,
  uploadFileToObjectStorage,
  getGlobalObjectStorageClient,
  abortUploadFileToObjectStorage,
  getObjectStorageFullUrl
}



// let myModule;
// if (condition) {
//   myModule = await import('./moduleA.js');
// } else {
//   myModule = await import('./moduleB.js');
// }

// // 使用 myModule
// console.log(myModule);
// let moduleToExport;

// if (condition) {
//   moduleToExport = { /* export something */ };
// } else {
//   moduleToExport = { /* export something else */ };
// }
// export default moduleToExport;