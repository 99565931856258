<template>
  <div class="height-all custom-button" :style="renderStyle">
    <el-button
      @click.stop="onClick"
      :loading="props.loading"
      :disabled="props.disabled"
    >
      <slot></slot>
    </el-button>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  readonly,
  reactive,
  watch,
  defineExpose,
  onMounted,
} from "vue";

import { useRouter, useRoute } from "vue-router";

const Router = useRouter();
const route = useRoute();
const defaultRenderStyle = {
  "--padding": "0 10px",
  "--width": "unset",
  "--height": "100%",
  "--borderRadius": "50px",
  "--background":
    "linear-gradient( 90deg, #2A8CFF 0%, #5074F5 45%, #5251FF 100%)",
  "--fontColor": "#fff",
  "--fontSize": "14px",
  "--fontWeight": "400",
  "--border": "none",
  "--backgroundHover": "rgba(255, 255, 255, 0.3)",
  "--fontColorHover": "#fff",
};
const props = defineProps({
  renderStyle: {
    type: Object,
    default: () => ({}),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const renderStyle = computed(() => {
  return { ...defaultRenderStyle, ...props.renderStyle };
});
const emits = defineEmits(["click"]);
const renderData = reactive({});
const onClick = (e) => {
  emits("click", e);
};
onMounted(() => {});
</script>
<style lang="scss">
.custom-button {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-button {
    z-index: 0;
    height: var(--height);
    width: var(--width);
    border-radius: var(--borderRadius);
    font-family: HarmonyOS Sans SC;
    font-weight: 400;
    font-size: var(--fontSize);
    font-weight: var(--fontWeight);
    color: var(--fontColor);
    line-height: var(--height);
    background: var(--background);
    border: var(--border);
    padding: var(--padding);
    position: relative;
    .el-icon.is-loading {
      display: block;
      height: 20px;
      width: 20px;
      min-width: 20px;
      min-height: 20px;
      background: transparent;
      background-image: url(@/assets/img/loading-mini-3x.png) !important;
      background-size: 100% 100% !important;
      animation: animation-rotate-infinite 0.75s infinite normal linear;
      opacity: 1;
      visibility: visible;
      z-index: 1;
      svg {
        display: none;
      }
    }
    &:hover {
      background: var(--backgroundHover);
      color: var(--fontColorHover);
    }
    @media screen and (max-width: 768px) {
      &:hover {
        background: var(--background);
        color: var(--fontColor);
      }
    }
    &.is-disabled {
      background: var(--background);
      opacity: 0.3;
      &.is-loading {
        opacity: 1;
        span {
          opacity: 0.5;
        }
      }
    }
    &.is-disabled:hover {
      background: var(--background);
      cursor: not-allowed;
    }
    &.is-loading {
      &:before {
        display: none;
      }
      span {
        opacity: 0.5;
        display: none;
      }
    }
  }
}
</style>
