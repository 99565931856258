<template>
  <div class="globle-player">
    <div class="globle-player-main-player flex1 width-0">
      <AudioPlayer
        v-if="renderData.ifRenderPlayer"
        ref="refAudioPlayer"
        theme="light"
        :switch="true"
        :loop="props.loop"
        :renderStyle="{
          '--opLoadingColor': '#fff',
          '--opSize': '48px',
          '--opIcoSize': '24px',
          '--opBgColor': 'transparent',
          '--opMargin': '0 70px',
          '--opSwitchSize': '36px',
          '--opSwitchIcoSize': '24px',
          '--opSwitchBgHover': 'rgba(0,0,0,0.1)',

          '--titleColor': '#fff',
          '--titleFontSize': '14px',
          '--titleFontWeight': '500',

          '--timeColor': '#FFFFFF4D',
          '--timeFontSize': '10px',
          '--timeFontWeight': 'normal',
          '--timeMinWidth': '36px',
          '--barColor': 'rgba(255, 255, 255,  0.3)',
          '--barHeight': '2px',

          '--seekbarMargin': '0',
          '--progressbarColor': '#fff',
          '--progressSliderSize': '8px',
          '--progressSliderColor': '#fff',
          '--progressSliderHoverColor': 'rgba(255, 255, 255, 0.3)',
        }"
        :url="renderData.url"
        :duration="renderData.duration"
        :highPosition="props.highPosition"
        @switch="doSwitch"
        @syncPlayState="doSyncPlayState"
        @syncProgress="doSyncProgress"
        @syncDuration="doSyncDuration"
        @syncLoadingState="doSyncLoadingState"
        @perContinuousPlaybackTimeCallback="onPerContinuousPlaybackTimeCallback"
        @play="doPlay"
      ></AudioPlayer>
    </div>

    <!-- <div
      class="globle-player-circle-mode globle-player-ico-btn pointer fn-inline"
      @click="toggleLoop"
    >
      <i
        class="block"
        :class="{
          'repeat-false': !renderData.loop,
          'repeat-true': renderData.loop,
        }"
      ></i>
    </div>
    <div class="slot-playlist fn-inline" v-if="$slots.playlist">
      <slot name="playlist"></slot>
    </div> -->

    <!-- <div class="globle-player-volume globle-player-ico-btn pointer fn-inline">
      <div class="globle-player-volume-slider">
        <el-slider
          v-model="renderData.volumeProgress"
          vertical
          @change="changeVolume"
          tooltip-class="globle-player-volume-slider-tooltip"
        >
        </el-slider>
      </div>
      <div class="globle-player-volume-toogle-contro">
        <i class="block"></i>
      </div>
    </div> -->
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  reactive,
  watch,
  defineExpose,
  onMounted,
  nextTick,
} from "vue";
import AudioPlayer from "./MobileAudioPlayer.vue";
import globalAudioPlayer from "./GlobalAudioPlayer.js";

const props = defineProps({
  url: {
    type: String,
    default: "",
  },
  duration: {
    type: Number,
    default: 0,
  },
  highPosition: {
    type: Number,
    default: null,
  },
  loop: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits([
  "log",
  "drawEqualizer",
  "syncDuration",
  "syncPlayState",
  "syncLoadingState",
  "syncProgress",
  "switch",
  "play",
  "curPlayEnded",
  "perContinuousPlaybackTimeCallback",
]);

const refAudioPlayer = ref(null);

const renderData = reactive({
  url: props.url,
  duration: props.duration,
  ifRenderPlayer: true,
  isPlaying: false,
  tempPlayStatus: false,
  loop: false,
  volumeProgress: 100,
  volume: 0,
  isLoading: false,
  progress: 0,
});

const init = () => {
  globalAudioPlayer.setGlobalPersonalizedConfig({
    endedToStart: true, // 播放结束后是否回归零位置
    loop: false, // 是否循环播放
    fadeInTime: 0, // 淡入时间
    fadeOutTime: 0, // 淡出时间
    volumeGain: 0, // 音量偏移，当前歌曲音量降低或增加0-1，音量最大为1
    analyser: false, // 创建频谱直方图
    canSeekNotCurrent: false, // 当不是当前播放时是否可以调整下次播放位置
    autoToStartIfNotCurrent: false, // 当不是当
  });

  globalAudioPlayer.registGlobleListener(
    "curPlayEnded",
    ({ currentUrl, player }) => {
      if (renderData.url == currentUrl) {
        if (!props.loop) {
          setTimeout(() => {
            emits("switch", "next");
          }, 20);
        }
      }
    }
  );
  renderData.url = props.url;
  renderData.duration = props.duration;
};

// const onCurPlayEnded = ({ currentUrl, player }) => {
//   if (renderData.url == currentUrl) {
//     emits("curPlayEnded", { currentUrl, player });
//     if (!renderData.loop) {
//       setTimeout(() => {
//         emits("switch", "next");
//       }, 20);
//     }
//   }
// };
const resetUrl = (url) => {
  nextTick().then(() => {
    renderData.ifRenderPlayer = false;
    nextTick(() => {
      renderData.url = url;
      renderData.duration = props.duration;
      renderData.ifRenderPlayer = true;
      // nextTick(() => {
      //   globalAudioPlayer.seek(renderData.url, 0);
      // });
    });
  });
};

const onPerContinuousPlaybackTimeCallback = (params) => {
  emits("perContinuousPlaybackTimeCallback", params);
};

const doSyncPlayState = (isPlaying) => {
  renderData.isPlaying = isPlaying;
};

const doSyncLoadingState = (isLoading) => {
  renderData.isLoading = isLoading;
};

const doSyncProgress = (progress, startTime) => {
  renderData.progress = progress;
  renderData.startTime = startTime;
};

const doSyncDuration = (duration) => {
  renderData.duration = duration;
};

const doPlay = (url) => {
  emits("play", url);
};

// const changeVolume = () => {
//   renderData.volume = renderData.volumeProgress / 100;
//   globalAudioPlayer.setVolume(renderData.volume);
// };

const doSwitch = (data) => {
  refAudioPlayer.value?.changeOp(false);
  nextTick(() => {}).then(() => {
    emits("switch", data);
  });
};

watch(
  () => renderData.isPlaying,
  () => {
    emits("syncPlayState", renderData.isPlaying);
  }
);
watch(
  () => renderData.isLoading,
  () => {
    emits("syncLoadingState", renderData.isLoading);
  }
);
watch(
  () => renderData.progress,
  () => {
    emits("syncProgress", renderData.progress, renderData.startTime);
  }
);
watch(
  () => renderData.duration,
  () => {
    emits("syncDuration", renderData.duration);
  }
);

watch(
  () => props.url,
  (newVal) => {
    if (renderData.url != props.url) {
      resetUrl(newVal);
    }
  }
);

onMounted(() => {
  init();
});
defineExpose({
  changeTempOp: (isPlay) => {
    if (isPlay) {
      if (renderData.tempPlayStatus) {
        refAudioPlayer.value?.changeOp(true);
      }
    } else {
      renderData.tempPlayStatus = renderData.isPlaying;
      refAudioPlayer.value?.changeOp(false);
    }
  },
  changeOp: (isPlay) => {
    refAudioPlayer.value?.changeOp(isPlay);
  },
});
</script>
<style lang="scss">
.globle-player-volume-slider-tooltip {
  pointer-events: none;
}
.globle-player {
  display: flex;
  align-items: center;
  .globle-player-toggle-op {
    margin-left: 12px;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background: linear-gradient(146deg, #98ff8e 9%, #3dd5ff 93%);
    margin: 0 14px;
  }
  .globle-player-ico-btn {
    height: 40px;
    width: 40px;
    padding: 12px;
    i {
      width: 16px;
      height: 16px;
    }
  }
  .globle-player-circle-mode {
    margin-left: 8px;
    i.repeat-true {
      background: url("@/assets/img/repeat-one-ico.png");
      background-size: 100% 100%;
    }
    i.repeat-false {
      background: url("@/assets/img/circle-ico.png");
      background-size: 100% 100%;
    }
  }
  .globle-player-playlist {
    i {
      background: url("@/assets/img/playlist-ico.png");
      background-size: 100% 100%;
    }
  }
  .globle-player-volume {
    padding: 0;
    position: relative;
    .globle-player-volume-toogle-contro {
      padding: 12px;
    }
    i {
      background: url("@/assets/img/volume-ico.png");
      background-size: 100% 100%;
    }
    .globle-player-volume-slider {
      position: absolute;
      display: none;
      bottom: 38px;
      left: 0;
      width: 38px;
      height: 150px;
      border-radius: 12px;
      background: rgb(16 54 73);
      backdrop-filter: blur(10px);
      padding: 4px 0;

      .el-slider.is-vertical {
        height: 112px;
        margin: 12px 0 0 0;
        .el-slider__runway {
          height: 100% !important;
          border-radius: 4px 4px 4px 4px;
          box-sizing: content-box;
          background-color: transparent;
          width: 2px;
          margin: 0 18px;
        }
        .el-slider__runway::before {
          margin-top: 6px;
          content: "";
          display: block;
          height: 100px;
          width: 100%;
          border-radius: 4px 4px 4px 4px;
          box-sizing: content-box;
          background-color: rgba(255, 255, 255, 0.2);
        }
        .el-slider__button {
          width: 12px;
          height: 12px;
          background: #94adff;
          transition: unset !important;
          vertical-align: unset;
          border: none;
        }
        .el-slider__button-wrapper {
          left: -11px;
          height: 24px;
          width: 24px;
          line-height: 24px;
          z-index: 0;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .el-slider__bar {
          height: 4px;
          width: 2px;
          left: 0;
          border-radius: 4px 4px 4px 4px;
          background: #94adff;
        }
      }
    }
    &:hover {
      .globle-player-volume-slider {
        display: block;
      }
    }
  }
}
</style>
