<template>
  <div class="user-modal" v-if="pageVisible">
    <div class="mask"></div>
    <div class="content">
      <div class="mobile-title">
        <div class="left">{{ $$t("mine.Editprofile") }}</div>
        <img @click="closePage" src="@/assets/img/mobile/icon-close.png" alt="" />
      </div>
      <div class="close-btn" @click="closePage">
        <img src="@/assets/img/ic_close.png" alt="" />
      </div>
      <div class="user-info">
        <div class="head-pic">
          <img @click="uploadfire" :src="imageUrl ?? getObjectStorageFullUrl(userDataStore?.userInfo?.user?.profile_image)" alt="" />
        </div>
        <div class="choose">
          <div @click="uploadfire" class="pointer chooseItem">
            <img src="@/assets/img/login_arrow.png" alt="" /> <span>{{ $$t("mine.Changephoto") }}</span>
          </div>
        </div>
        <div>
          <el-form class="from-style" ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon>
            <el-form-item label="" prop="username">
              <Input
                :value="ruleForm.username"
                :placeholder="$$t('mine.username')"
                :prefix="LoginUser"
                @change="
                  (val) => {
                    update('username', val)
                  }
                "
              />
            </el-form-item>
          </el-form>
        </div>
        <div>
          <Button
            type="primary"
            class="bottom-btn"
            :renderStyle="{
              '--width': '100%',
              '--height': '48px',
              '--borderRadius': '50px',
              '--background': 'linear-gradient(97deg, #4FDEFF 0%, #E675FF 100%)',
              '--backgroundHover': 'linear-gradient(97deg, rgba(79,222,255,0.8) 0%, rgba(230,117,255,0.8) 100%)',
              '--fontColor': '#000',
              '--fontColorHover': '#000',
              '--fontSize': '16px',
              '--fontWeight': 'bold',
              '--border': 'none',
            }"
            @click="onSubmit"
            >{{ $$t("mine.Save") }}</Button
          >
        </div>
      </div>
    </div>
    <input type="file" ref="fileInput" style="display: none" @change="handleUploadFile" />
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, onMounted } from "vue"
import useUserDataStore from "@/store/userDataStore"
import Input from "@/components/basic/input/LoginInput2.vue"
import LoginUser from "@/assets/img/login_user.png"
import objectStorage from "@/lib/objectStorage/objectStorage.js"
import { getObjectStorageFullUrl } from "@/lib/objectStorage/objectStorage.js"
import apiUser from "@/api/api/apiUser.js"
import message from "@/components/functionCallComponent/message/message.js"
import { $$language, $$t } from "@/i18n/i18n.js"
import { useRouter, useRoute } from "vue-router"

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  handleOk: {
    type: Function, //成功回调
    default: null,
  },
  remove: {
    type: Function, //传入移除节点方法,这里是createApp中的方法
    default: null,
  },
})
const userDataStore = useUserDataStore()
const emits = defineEmits([])
const ruleFormRef = ref(null)
const fileInput = ref(null)
const pageVisible = ref(false)
const isFinished = ref(false)
const isUploading = ref(false)
const imageUrl = ref(getObjectStorageFullUrl(userDataStore?.userInfo?.user?.profile_image))

pageVisible.value = props.visible

// 监听显示的消失，需要移除dom
watch(
  () => pageVisible.value,
  (val) => {
    !val && props.remove()
  }
)

const rules = {
  username: [
    { required: true, message: $$t("mine.Usernameisrequired"), trigger: "blur" },
    { max: 30, message: $$t("mine.max30"), trigger: 'blur' }
    // {
    //   validator: (rule, value) => {
    //     const regExp = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/
    //     if (!regExp.test(value)) {
    //       return new Error("Only letters(a-z)/numbers(0-9) can be entered")
    //     } else {
    //       return true
    //     }
    //   },
    //   trigger: "blur",
    // },
  ],
}

const ruleForm = reactive({
  username: userDataStore?.userInfo?.user?.stage_name ?? "", // 'test123',
})

const closePage = () => {
  pageVisible.value = false
}

const extractFilePath = (url) => {
  const match = url.match(/^(?:https?:\/\/)?(?:localhost|\d{1,3}(?:\.\d{1,3}){3}|[^\/]+)(:\d+)?\/?(.*)$/);
  return match ? match[2] : '';
}

const onSubmit = async () => {
  const { username } = ruleForm
  const updateUrl = imageUrl.value? extractFilePath(imageUrl.value):""
  await ruleFormRef.value.validate(async (valid, fields) => {
    if (valid) {
      const { data, code, msg } = await apiUser.updateUserInfo({
        user: {
          stage_name: username,
          ...(updateUrl ? { profile_image: updateUrl } : {}),
        },
        // profile_image: '',
      })
      if (code === 200) {
        const userInfo = userDataStore.userInfo
        userInfo.user.stage_name = username
        userInfo.user.profile_image = imageUrl.value
        console.log("userInfo", userInfo)
        userDataStore.updateUserInfo(userInfo)
        message.success({
          maskClosable: true,
          position: "top",
          content: $$t("mine.Profileupdated"),
        })
        // closePage()
      } else if (code === 6319) {
        message.error({
          maskClosable: true,
          position: "top",
          content: $$t("library.textinvalid"),
        })
      } else if (code === 6320) {
        message.error({
          maskClosable: true,
          position: "top",
          content: $$t("library.imageinvalid"),
        })
      } else {
        message.error({
          maskClosable: true,
          position: "top",
          content: msg,
        })
      }
    } else {
      console.log("error submit!", fields)
    }
  })
}

const update = (key, val) => {
  ruleForm[key] = val
}

const uploadfire = async () => {
  if (!(await userDataStore.checkLoginStatus())) {
    return
  }
  fileInput.value.click()
}

// 文件上传
const handleUploadFile = async () => {
  isFinished.value = false
  let file = fileInput.value.files[0]
  let validTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif"]
  if (!file) return
  console.log("file.type", file.type)
  if (validTypes.indexOf(file.type) === -1) {
    message.warning({
      maskClosable: true,
      content: $$t("mine.png"),
      position: "top",
    })
    isFinished.value = true
    return
  }
  const maxFileSize = 10 * 1024 * 1024
  if (file.size > maxFileSize) {
    message.warning({
      maskClosable: true,
      content: $$t("mine.10max"),
      position: "top",
    })
    return
  }
  isUploading.value = true
  const closeMsg = message.loading({ content: $$t("mine.uploading") })
  objectStorage
    .uploadFileToObjectStorage(file)
    .then((res) => {
      console.log("res", res)
      closeMsg.clear()
      if (!isFinished.value) {
        imageUrl.value = getObjectStorageFullUrl(res.pathKey)
        console.log("url", imageUrl.value)
        isUploading.value = false
      }
    })
    .catch((err) => {
      closeMsg.clear()
      message.error({
        maskClosable: true,
        content: $$t("create.upload_failed"),
        position: "top",
      })
      isUploading.value = false
    })
}

onMounted(() => {
  console.log("userInfo onMounted")
})
</script>

<style lang="scss" scoped>
.user-modal {
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 102;
  .mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
  }
  .mobile-title {
    display: none;
  }
  .content {
    width: 520px;
    height: 376px;
    padding: 0 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url("@/assets/img/dialog-bg-1.webp");
    background-color: rgba(148,173,255, 0.3);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    border-radius: 32px;
    z-index: 105;
    .close-btn {
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 24px;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 24px;
        height: 24px;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    .user-info {
      padding-top: 40px;
      .head-pic {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        img {
          width: 120px;
          height: 120px;
          border-radius: 50%;
        }
      }
      .choose {
        text-align: center;
        padding: 12px 0 24px 0;
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: normal;

        .chooseItem {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
      .from-style {
        margin-bottom: 24px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .content {
      width: 100%;
      height: unset;
      position: absolute;
      top: unset;
      bottom: 0;
      left: 0;
      transform: none;
      background: rgba(71, 74, 74, 0.5);
      backdrop-filter: blur(100px);
      border-radius: unset;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      padding: 0 16px;
      box-sizing: border-box;
      .close-btn {
        display: none;
      }
      .user-info {
        padding-top: 32px;
      }
      .mobile-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        .left {
          font-size: 24px;
          font-weight: bold;
          color: #ffffff;
        }
        img {
          width: 40px;
          height: 40px;
        }
      }
      .bottom-btn {
        margin-bottom: 44px;
      }
    }
  }
}
</style>
