import { defineStore } from "pinia";
import { ref, nextTick } from "vue";
import tools from "@/utils/tools.js";
import globalConfig from "@/config/config.js";
import apiReferMusic from "@/api/api/apiReferMusic.js";
import message from "@/components/functionCallComponent/message.jsx";
import { $$t } from "@/i18n/i18n.js";

import useMuseDataStore from "@/store/create/museDataStore.js";
import { getObjectStorageFullUrl } from "@/lib/objectStorage/objectStorage.js";
import useDraftDataStore from "./draftDataStore.js";

// https://rg975ojk5z.feishu.cn/wiki/WvhHw2ZTgicqKekQEtecYKaTnNo
export const TabDataSuffixMap = {
  1: "all",
  2: "used",
  3: "upload",
};

const MapReferMusicApi = {
  1: apiReferMusic.getAllReferMusics,
  2: apiReferMusic.getUsedReferMusics,
  3: apiReferMusic.getMineReferMusics,
};

const userReferMusicStore = defineStore({
  id: "referMusicStore",
  state: () => {
    return {
      datas_all: [],
      datas_used: [],
      datas_upload: [],
      loadmoreid_all: "",
      loadmoreid_use: "",
      loadmoreid_upload: "",
      isloading_all: false,
      hasInit_all: false,
      isloading_use: false,
      hasInit_use: false,
      isloading_upload: false,
      hasInit_upload: false,
      curUseReferMusic: null,
      loadtimestamp_all: "",
      loadtimestamp_use: "",
      loadtimestamp_upload: "",
      filterAllData: {},
      curUseMusicTabCode: null,
    };
  },
  actions: {
    initReferMusic(data) {
      this.updateCurUseReferMusic(data?.refer ?? null);
    },
    updateCurUseReferMusic(data, tabCode) {
      this.curUseReferMusic = data;
      useMuseDataStore().setReferenceData(data);
      this.curUseMusicTabCode = tabCode;
    },
    getReferMusic() {
      return this.curUseReferMusic;
    },

    setFilterAllData(data) {
      this.filterAllData = data;
    },

    init(params, done, errordone, isSearch, showLoading) {
      const { query_type } = params;
      console.log("initreferdata", params);
      let closeMsg = null;
      return new Promise((resolve, reject) => {
        if (showLoading) {
          closeMsg = message.loading({
            position: "center",
            content: $$t("create.loading"),
          });
        }

        this[`isloading_${TabDataSuffixMap[query_type]}`] = true;
        this.getdatas(params, done, errordone, isSearch ?? true)
          .then((datas) => {
            this[`hasInit_${TabDataSuffixMap[query_type]}`] = true;
            this[`isloading_${TabDataSuffixMap[query_type]}`] = false;
            this[`hasInit${TabDataSuffixMap[query_type]}`] = false;
            resolve(datas);
            closeMsg && closeMsg.clear();
          })
          .catch((err) => {
            closeMsg && closeMsg.clear();
            this[`isloading_${TabDataSuffixMap[query_type]}`] = false;
            reject(err);
          });
      });
    },
    getdatas(params, done, errordone, isSearch) {
      const { query_type } = params;
      let loadtimestamp = new Date().getTime();
      this[`loadtimestamp_${TabDataSuffixMap[query_type]}`] = loadtimestamp;
      const getReferMusics = MapReferMusicApi[query_type];

      return new Promise(async (resolve, reject) => {
        getReferMusics(params)
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              if (
                !isSearch ||
                this[`loadtimestamp_${TabDataSuffixMap[query_type]}`] ==
                  loadtimestamp
              ) {
                this[`datas_${TabDataSuffixMap[query_type]}`] = data.list ?? [];
                this[`loadmoreid_${TabDataSuffixMap[query_type]}`] =
                  data.last_id;
              }
              done && done(data.list ?? [], !data.last_id);
              resolve(this[`datas_${TabDataSuffixMap[query_type]}`]);
            } else {
              errordone && errordone(code == -200);
              // if (code == -200) {
              //   message.error({position: "top", content: msg });
              // } else {
              //   message.error({position: "top", content: "加载失败" });
              // }
              reject(this[`datas_${TabDataSuffixMap[query_type]}`]);
            }
          })
          .catch((err) => {
            errordone && errordone();
            reject(err);
            //   message.error({position: "top", content: "加载失败" });
          });
      });
    },
    async refresh(params, done, errordone) {
      const { query_type } = params;
      if (this[`isloading_${TabDataSuffixMap[query_type]}`]) {
        done && done();
        return;
      }
      this[`isloading_${TabDataSuffixMap[query_type]}`] = true;
      this.getdatas(params, done, errordone)
        .then((res) => {
          this[`isloading_${TabDataSuffixMap[query_type]}`] = false;
        })
        .catch((e) => {
          this[`isloading_${TabDataSuffixMap[query_type]}`] = false;
        });
    },
    async loadMore(params, done, errordone) {
      const { query_type } = params;
      params.last_id = this[`loadmoreid_${TabDataSuffixMap[query_type]}`];
      if (this[`isloading_${TabDataSuffixMap[query_type]}`]) {
        done && done();
        return;
      }
      if (!params.last_id) {
        done && done();
        return;
      }
      this[`isloading_${TabDataSuffixMap[query_type]}`] = true;
      const getReferMusics = MapReferMusicApi[query_type];

      getReferMusics(params)
        .then((res) => {
          const { data, code, msg } = res;
          if (code == 200) {
            this[`datas_${TabDataSuffixMap[query_type]}`] = this[
              `datas_${TabDataSuffixMap[query_type]}`
            ].concat(data.list ?? []);
            this[`loadmoreid_${TabDataSuffixMap[query_type]}`] = data.last_id;
          }
          //  else {
          //   message.error({ content: msg });
          // }

          nextTick(() => {
            if (code == 200) {
              done && done(data.list ?? [], !data.last_id);
            } else {
              errordone && errordone(code == -200);
            }
            this[`isloading_${TabDataSuffixMap[query_type]}`] = false;
          });
        })
        .catch((err) => {
          errordone && errordone(err);
        });
    },
    insertUploadData(refer) {
      this[`datas_${TabDataSuffixMap[3]}`] = [
        refer,
        ...this[`datas_${TabDataSuffixMap[3]}`],
      ];
    },
    deleteCurUseMusicByAudit(refer) {
      if (refer != null && refer.id == this.curUseReferMusic?.id) {
        this.deleteReferItemFromList(refer, true);
        this.updateCurUseReferMusic(null, null);
      }
    },
    setCurUseMusic(refer, tabCode) {
      // let lastUseMusic = this.curUseReferMusic;
      this.updateCurUseReferMusic(refer ?? null, tabCode);
      // const draftDataStore = useDraftDataStore();
      // if (refer == null && lastUseMusic != null && lastUseMusic.id) {
      //   // const closeMsg=  message.loading({content: '取消中'})
      //   apiReferMusic
      //     .deleteReferenceMusic({
      //       feed_id: draftDataStore.feed_id,
      //       control_type: 1,
      //       refer_id: lastUseMusic.id,
      //     })
      //     .then((res) => {
      //       if (res.code == 200) {
      //       } else {
      //         return;
      //       }
      //     })
      //     .catch((err) => {
      //       //  closeMsg.clear();
      //     });
      // } else {
      // }
    },
    deleteReferItemFromList(refer, isDeleteUse) {
      let index_used = this.datas_used.findIndex((item) => item.id == refer.id);
      let index_all = this.datas_all.findIndex((item) => item.id == refer.id);

      let index_upload = this.datas_upload.findIndex(
        (item) => item.id == refer.id
      );
      if (index_used >= 0 && isDeleteUse) {
        this.datas_used.splice(index_used, 1);
        this.datas_used = [].concat(this.datas_used);
      }
      if (index_all >= 0) {
        this.datas_all.splice(index_all, 1);
        this.datas_all = [].concat(this.datas_all);
      }
      if (index_upload >= 0) {
        this.datas_upload.splice(index_upload, 1);
        this.datas_upload = [].concat(this.datas_upload);
      }
    },
    deleteReferItem(refer, query_type) {
      let closeMsg = message.loading({
        position: "center",
        content: $$t("create.deleting"),
      });
      apiReferMusic
        .deleteReferMusicItem({ id: refer.id })
        .then((res) => {
          closeMsg.clear();
          if (res.code == 200) {
            if (this.curUseReferMusic?.id == refer.id) {
              this.updateCurUseReferMusic(null, null);
            }
            this.deleteReferItemFromList(refer);
            message.success({
              position: "top",
              content: $$t("create.delete_success"),
            });
          } else {
            message.error({
              position: "top",
              content: $$t("create.delete_failed"),
            });
          }
        })
        .catch((err) => {
          message.error({
            position: "top",
            content: $$t("create.delete_failed"),
          });
          closeMsg.clear();
        });
    },
    doEditSongTitle(refer, query_type) {},
  },
});
export default userReferMusicStore;
// export const referMusicStore = userReferMusicStore()
